import type { GetResourceResponse, LngLatLike, RequestParameters } from "maplibre-gl";

import { makeRequest } from "maplibre-gl/src/util/ajax";
import { getAccessToken, getApiUrl } from ".";

export function makeNMapsRequest(requestParameters: RequestParameters, abortController?: AbortController): Promise<GetResourceResponse<any>> {
    const url = requestParameters.url;
    const urlParts = url.split("?");
    const urlParams = new URLSearchParams(urlParts[ 1 ]);
    urlParams.set("access_token", getAccessToken());
    requestParameters.url = `${urlParts[ 0 ]}?${urlParams.toString()}`;

    if (requestParameters.type === "json") {
        if (!requestParameters.headers) requestParameters.headers = {};
        requestParameters.headers[ "Content-Type" ] = "application/json";
    }
    return makeRequest(requestParameters, abortController || new AbortController());
}

// CORE API - SEARCH
export function reverseGeocode(coords, abortController: AbortController, maxResults = 1, result_types?: string[], language = "en") {
    const bodyRequest = {
        language,
        "location": {
            "lat": coords[ 0 ],
            "lng": coords[ 1 ]
        },
        "max_results": maxResults,
        "allow_unnamed": false
    };
    if (result_types) {
        bodyRequest[ "result_types" ] = result_types;
    }

    return makeNMapsRequest({
        url: `${getApiUrl()}/v1/search/reverse_geocode`,
        method: "POST",
        body: JSON.stringify(bodyRequest),
        type: "json"
    }, abortController);
}

export function genericSearch(input, refLocation, abortController: AbortController, maxResults = 5) {
    const bodyRequest = {
        "search_text": input,
        "ref_location": {
            "lat": refLocation.lat,
            "lng": refLocation.lng
        },
        "max_results": maxResults
    };

    return makeNMapsRequest({
        url: `${getApiUrl()}/v1/search/generic_search`,
        method: "POST",
        body: JSON.stringify(bodyRequest),
        type: "json"
    }, abortController);
}

// CORE API - PLACES
export function findNearBy(refLocation, categories, radius, maxResults = 1, language = "en") {
    const bodyRequest = {
        language,
        categories,
        radius,
        "max_results": maxResults,
        "ref_location": {
            "lat": refLocation.lat,
            "lng": refLocation.lng
        },
    };

    return makeNMapsRequest({
        url: `${getApiUrl()}/v1/places/find_nearby`,
        method: "POST",
        body: JSON.stringify(bodyRequest),
        type: "json"
    });
}

// CORE API - ROADS
export function roadsInfo(coords: LngLatLike, language = "en") {
    const bodyRequest = {
        language,
        "locations": [ coords ]
    };

    return makeNMapsRequest({
        url: `${getApiUrl()}/v1/roads/info`,
        method: "POST",
        body: JSON.stringify(bodyRequest),
        type: "json"
    });
}

// CORE API - TIMEZONE
export function timeZoneInfo(coords: LngLatLike, language = "en") {
    const bodyRequest = {
        language,
        "locations": [ coords ]
    };

    return makeNMapsRequest({
        url: `${getApiUrl()}/v1/timezone/info`,
        method: "POST",
        body: JSON.stringify(bodyRequest),
        type: "json"
    });
}

// CORE API - ROUTING
export function getDirections(waypoints, profile, abortController: AbortController, departure_time, geometry = true, alternatives = 0, directions = true, traffic = false) {
    const bodyRequest = {
        profile,
        waypoints,
        geometry,
        alternatives,
        directions,
        traffic,
        departure_time
    };

    return makeNMapsRequest({
        url: `${getApiUrl()}/v1/routing/calc`,
        method: "POST",
        body: JSON.stringify(bodyRequest),
        type: "json"
    }, abortController);
}
