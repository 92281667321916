import type { Map, MarkerOptions } from "maplibre-gl";

import { Marker as _Marker } from "maplibre-gl";
import { DOM } from "maplibre-gl/src/util/dom";
import { markerColor } from "~/constants";

export class Marker extends _Marker {

    constructor(options?: MarkerOptions) {
        const color = options && options.color || markerColor;
        const scale = options && options.scale || 1;

        if (!options) options = {};

        if (options && !options.element) {
            const elem = DOM.create("div");
            elem.setAttribute("aria-label", "Map marker");

            const svg = DOM.createNS("http://www.w3.org/2000/svg", "svg");
            const defaultHeight = 41;
            const defaultWidth = 27;

            svg.setAttributeNS(null, "display", "block");
            svg.setAttributeNS(null, "height", `${defaultHeight}px`);
            svg.setAttributeNS(null, "width", `${defaultWidth}px`);
            svg.setAttributeNS(null, "viewBox", `0 0 ${defaultWidth} ${defaultHeight}`);

            svg.innerHTML = `<g id="shape" transform="translate(0.000000, 0.500000)" fill-rule="nonzero">
                <path d="M13.2,0 C20.4,0 27,6.40417457 27,14.0891841 C27,21.7741935 13.8,39.7058824 13.8,39.7058824 C13.8,39.7058824 0,22.414611 0,14.0891841 C0,5.76375712 6,0 13.2,0 Z M13.5,7.34452087 C9.90412325,7.34452087 6.98908918,10.2595549 6.98908918,13.8554317 C6.98908918,17.4513084 9.90412325,20.3663425 13.5,20.3663425 C17.0958768,20.3663425 20.0109108,17.4513084 20.0109108,13.8554317 C20.0109108,10.2595549 17.0958768,7.34452087 13.5,7.34452087 Z"
                    id="Combined-Shape" fill=${color}>
                </path>
                <circle id="Oval" fill-opacity="0.5" fill="#FFFFFF" cx="13.5" cy="13.8554317" r="6.51091082"></circle>
            </g>`;

            svg.setAttributeNS(null, "height", `${defaultHeight * scale}px`);
            svg.setAttributeNS(null, "width", `${defaultWidth * scale}px`);

            elem.appendChild(svg);

            options.element = elem;
        }

        super(options);
        this._color = color;
        this._scale = scale;
    }

    addTo(map: Map) {
        const r = super.addTo(map);
        this._updateColor();
        if (this._map) this._map.on("styledata", this._updateColor);
        return r;
    }

    remove() {
        if (this._map) this._map.off("styledata", this._updateColor);
        return super.remove();
    }

    _updateColor = () => {
        if (!this._map) return;

        const style = this._map.getStyle();

        if (this._color === markerColor && style) {
            if (/dark/i.test(style.name) || /light/i.test(style.name)) {
                this._element.classList.add("nmapsgl-marker-dark");
            } else {
                this._element.classList.remove("nmapsgl-marker-dark");
            }
        }
    };

}
