import type { ControlPosition, IControl } from "maplibre-gl";
import type { Map } from "~/ui/map";

import { addTooltip, controlButton, controlContainer } from "~/utils";
import { StreetViewLayer } from "../layers";

export class StreetViewControl implements IControl {

    private map: Map;
    private container: HTMLElement;
    private ctrlButton: HTMLElement;

    onAdd(map: Map): HTMLElement {
        this.map = map;
        const instance = this.map.addLayerInstance(StreetViewLayer);
        this.container = controlContainer();
        this.ctrlButton = this.container.appendChild(controlButton({
            title: StreetViewLayer.title,
            className: "nmapsgl-ctrl-streetview",
            onClick: this.onCtrlClick
        }));
        addTooltip(this.ctrlButton);

        if (instance.isEnabled()) this.ctrlButton.classList.add("active");
        instance.on("show", this.onShow);
        instance.on("hide", this.onHide);
        return this.container;
    }

    onRemove(): void {
        const instance = this.map.getLayerInstance(StreetViewLayer.id);
        instance.off("show", this.onShow);
        instance.off("hide", this.onHide);
        delete this.map;
    }

    getDefaultPosition?: () => ControlPosition = () => "top-right";

    private onCtrlClick = () => {
        const instance = this.map.getLayerInstance(StreetViewLayer.id);
        instance.setState(!instance.isEnabled());
    };

    private onShow = () => {
        this.ctrlButton.classList.add("active");
    };

    private onHide = () => {
        this.ctrlButton.classList.remove("active");
    };
}
