import type { LayerSpecification, LngLatLike, Map, SourceSpecification } from "maplibre-gl";
import type { IShape } from "./shape";

import { LngLat } from "maplibre-gl";
import { extend } from "maplibre-gl/src/util/util";
import { uniqueId } from "~/utils";

type PolylineOptions = {
    strokeColor?: string,
    strokeOpacity?: number,
    strokeWeight?: number
};

const defaultOptions: PolylineOptions = {
    strokeColor: "#000000",
    strokeOpacity: 1,
    strokeWeight: 3
};

function validateOptions(options: PolylineOptions) {
    if (options.strokeOpacity < 0 || options.strokeOpacity > 1) throw Error("Invalid stroke opacity");
    if (options.strokeWeight < 0) throw Error("Invalid stroke weight");
    return true;
}

/**
 * Creates a polyline component.
 * @param {Object} [options]
 * @param {string} [options.strokeColor] The color with which the line will be drawn. The default is black (#000000).
 * @param {number} [options.strokeOpacity] Specifies a numerical value between 0.0 and 1.0 to determine the opacity of the line color. The default is 1.0.
 * @param {number} [options.strokeWeight] The width of the line in pixels. The default is 3.
 * @example
 * const coordinates = [
 *   [-122.483696, 37.833818],
 *   [-122.483482, 37.833174],
 *   [-122.483396, 37.8327]
 * ];
 *
 * let polyline = new nmapsgl.Polyline()
 *   .setPath(coordinates)
 *   .addTo(map);
 * @example
 * // Set options
 * let polyline = new nmapsgl.Polyline({
 *     strokeColor: '#888',
 *     strokeWeight: 8
 *   }).setPath(coordinates)
 *   .addTo(map);// add the polyline to the map
 */
export class Polyline implements IShape {
    _map: Map;
    _polylineID: string;
    _pathLnglat: LngLat[];
    private _options: PolylineOptions;

    constructor(options: PolylineOptions) {
        this._options = extend({}, defaultOptions, options);
        validateOptions(this._options);
    }

    /**
     * Get the polyline's geographical positions.
     *
     * @returns {LngLat[]} A {@link LngLat} array with the points that form the polyline.
     * @example
     * // Store the polyline points (longitude and latitude coordinates) in a variable
     * const points = polyline.getPath();
     * // Print the point's longitude and latitude values in the console
     * points.forEach((item) => {
     *   console.log('Longitude: ' + item.lng + ', Latitude: ' + item.lat );
     * });
     */
    getPath(): LngLat[] {
        return this._pathLnglat;
    }

    /**
     * Set the polyline's geographical positions.
     * @param {LngLatLike[]} path A {@link LngLat} array with the points that form the polyline.
     * @returns {Polyline} `this`
     * @example
     * // Create a new polyline, set the points and add it to the map.
     * const coordinates = [
     *   [-122.483696, 37.833818],
     *   [-122.483482, 37.833174],
     *   [-122.483396, 37.8327]
     * ];
     *
     * let polyline = new nmapsgl.Polyline()
     *   .setPath(coordinates)
     *   .addTo(map);
     */
    setPath(path: LngLatLike[]): this {
        this._pathLnglat = path.map(e => LngLat.convert(e));
        return this;
    }

    /**
     * Attaches the `Polyline` to a `Map` object.
     * @param {Map} map The NMaps GL JS map to add the polyline to.
     * @returns {Polyline} `this`
     * @example
     * const coordinates = [
     *   [-122.483696, 37.833818],
     *   [-122.483482, 37.833174],
     *   [-122.483396, 37.8327]
     * ];
     *
     * let polyline = new nmapsgl.Polyline({
     *     strokeColor: '#888',
     *     strokeWeight: 8
     *   }).setPath(coordinates)
     *   .addTo(map);// add the polyline to the map
     */
    addTo(map: Map): this {
        this.remove();
        this._map = map;
        // Create polyline ID
        this._polylineID = uniqueId("polyline");

        // Generate Source and Layer to polyline
        this._map.on("styledata", this._addSource);

        return this;
    }

    _addSource = () => {
        const hasSource = !!this._map.getSource(this._polylineID);
        if (hasSource) return;

        const lineSource: SourceSpecification = {
            "type": "geojson",
            "data": {
                "type": "Feature",
                "properties": {},
                "geometry": {
                    "type": "LineString",
                    "coordinates": this._pathLnglat.map(e => e.toArray())
                }
            }
        };
        const layer: LayerSpecification = {
            "id": this._polylineID,
            "type": "line",
            "source": this._polylineID,
            "layout": {
                "line-join": "round",
                "line-cap": "round"
            },
            "paint": {
                "line-color": this._options.strokeColor,
                "line-width": this._options.strokeWeight,
                "line-opacity": this._options.strokeOpacity
            }
        };

        this._map.addSource(this._polylineID, lineSource);
        this._map.addLayer(layer);
    };

    /**
     * Removes the polyline from a map.
     * @example
     * const coordinates = [
     *   [-122.483696, 37.833818],
     *   [-122.483482, 37.833174],
     *   [-122.483396, 37.8327]
     * ];
     *
     * let polyline = new nmapsgl.Polyline()
     *   .setPath(coordinates)
     *   .addTo(map);
     *
     * polyline.remove();
     * @returns {Polyline} `this`
     */
    remove(): this {
        if (this._map) {
            if (this._map.getLayer(this._polylineID)) this._map.removeLayer(this._polylineID);
            this._map.removeSource(this._polylineID);

            this._map.off("styledata", this._addSource);

            delete this._map;
        }
        return this;
    }

}
