import type { Map } from "maplibre-gl";

import { GeolocateControl as _GeolocateControl } from "maplibre-gl";
import { addTooltip, waitForDOMElement } from "~/utils";

export class GeolocateControl extends _GeolocateControl {

    onAdd(map: Map): HTMLElement {
        const result = super.onAdd(map);
        waitForDOMElement(".maplibregl-ctrl-geolocate", this._container)
            .then(elem => addTooltip(elem, "Show your location"));
        return result;
    }
}
