import type { Map } from "maplibre-gl";

import { LogoControl as _LogoControl } from "maplibre-gl";

export class LogoControl extends _LogoControl {

    onAdd(map: Map): HTMLElement {
        const result = super.onAdd(map);
        this._map.on("styledata", this.setStyle);
        return result;
    }

    onRemove() {
        super.onRemove();
        this._map.off("styledata", this.setStyle);
    }

    setStyle = () => {
        const anchor = this._container.childNodes[ 0 ] as HTMLAnchorElement;
        anchor.href = "https://www.ndrive.com/";
        anchor.setAttribute("aria-label", "NDrive logo");
        const style = this._map.getStyle();
        const darkMode = style && /dark/i.test(style.name);
        if (darkMode) anchor.classList.add("nmapsgl-dark");
        else anchor.classList.remove("nmapsgl-dark");
    };

}
