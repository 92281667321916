import type { Map } from "maplibre-gl";

import { NavigationControl as _NavigationControl } from "maplibre-gl";
import { addTooltip } from "~/utils";

export class NavigationControl extends _NavigationControl {

    onAdd(map: Map): HTMLElement {
        const result = super.onAdd(map);
        const { showCompass, showZoom } = this.options;
        if (showZoom) {
            this._zoomInButton.removeAttribute("title");
            this._zoomOutButton.removeAttribute("title");
        }
        if (showCompass) {
            this._compass.removeAttribute("title");
        }
        const tooltipText = (showCompass && showZoom) ? "Navigation" : (
            showCompass ? "Compass" : "Zoom"
        );
        addTooltip(result, tooltipText);
        return result;
    }
}
