import type { DebouncedFunc } from "lodash";
import type { MapGeoJSONFeature, MapLayerMouseEvent } from "maplibre-gl";
import type { Unit } from "~/types";
import type { Map } from "~/ui";
import type { LayerFilter, LayerInstanceOptions } from "./layer_instance";

import debounce from "lodash.debounce";
import { Popup } from "maplibre-gl";
import { extend } from "maplibre-gl/src/util/util";
import { html } from "~/utils";
import { LayerInstance } from "./layer_instance";

export type TrafficLayerOptions = {
    unit?: Unit,
    interaction?: "click" | "hover"
};

const defaultOptions: TrafficLayerOptions & LayerInstanceOptions = {
    unit: "Metric",
    interaction: "click",
    initialState: {
        traffic: true,
        closed_streets: true,
        warning_events: true
    }
};

export const trafficSourceName = "ndrive-traffic";
export const closedStreetsRegExp = /^(.*?)closure(.*?)$/;
export const trafficEventsId = "traffic_events";
export const trafficRegExp = /^(?!.*closure|.*events|.*traffic_speed).*$/;
export const speedLayers = {
    "Metric": /traffic_speed_\w+_kph.*/,
    "Imperial": /traffic_speed_\w+_mph.*/
};

export class TrafficLayer extends LayerInstance<TrafficLayerOptions> {
    static readonly id = "nmapsgl_traffic";
    static readonly title = "Traffic";

    private debounceFn: DebouncedFunc<(e: MapLayerMouseEvent) => void>;
    private popup: Popup;
    private legend = [
        { label: "Minimal", background: "#126290" },
        { label: "Low", background: "#4EC75B" },
        { label: "Moderate", background: "#ED7206" },
        { label: "High", background: "#DC1D1D" },
        { label: "Severe", background: "#8E1717" },
    ];

    constructor(options?: TrafficLayerOptions & LayerInstanceOptions) {
        options = extend({}, defaultOptions, options);
        const filters: LayerFilter[] = [
            {
                id: "closed_streets",
                filter: l => l[ "source" ] === trafficSourceName && closedStreetsRegExp.test(l.id),
                label: "Road closure",
            },
            {
                id: "traffic",
                filter: l => l[ "source" ] === trafficSourceName && (trafficRegExp.test(l.id) || speedLayers[ options.unit ].test(l.id)),
                label: "Flow",
            },
            {
                id: "warning_events",
                filter: l => l[ "source" ] === trafficSourceName && l.id === trafficEventsId,
                label: "Warning events",
            }
        ];
        super(filters, options);
    }

    onAdd(map: Map): void {
        super.onAdd(map);

        const { interaction } = this.options;

        if (interaction === "hover") {
            this.debounceFn = debounce(this.renderPopup, 500);
            this.map.on("mouseenter", trafficEventsId, this.debounceFn);
        } else {
            this.map.on("click", trafficEventsId, this.renderPopup);
        }
        this.map.on("mouseenter", trafficEventsId, this.onMouseEnter);
        this.map.on("mouseleave", trafficEventsId, this.onMouseLeave);
        this.on("change", this.onChange);
    }

    onRemove(): void {
        const { interaction } = this.options;

        if (interaction === "hover") {
            this.map.off("mouseenter", trafficEventsId, this.debounceFn);
        } else {
            this.map.off("click", trafficEventsId, this.renderPopup);
        }
        this.map.off("mouseenter", trafficEventsId, this.onMouseEnter);
        this.map.off("mouseleave", trafficEventsId, this.onMouseLeave);
        this.off("change", this.onChange);
        super.onRemove();
    }

    private renderPopup = (e: MapLayerMouseEvent) => {
        const geometry = e.features[ 0 ].geometry as GeoJSON.Point;
        const coordinates = geometry.coordinates.slice() as [ number, number ];

        while (Math.abs(e.lngLat.lng - coordinates[ 0 ]) > 180) {
            coordinates[ 0 ] += e.lngLat.lng > coordinates[ 0 ] ? 360 : -360;
        }

        if (this.popup) this.popup.remove();
        this.popup = new Popup({ closeButton: false, offset: [ 0, -25 ], className: "nmapsgl-traffic-events-popup" })
            .setLngLat(coordinates)
            .setHTML(this.getHTMLPopUp(e.features[ 0 ].properties))
            .addTo(this.map);
    };

    private onMouseEnter = () => {
        this.map.getCanvas().style.cursor = "pointer";
    };

    private onMouseLeave = () => {
        const { interaction } = this.options;

        this.map.getCanvas().style.cursor = "";
        if (interaction === "click") return;
        if (this.popup) {
            this.popup.remove();
            delete this.popup;
        }
        if (this.debounceFn) {
            this.debounceFn.cancel();
            delete this.debounceFn;
        }
    };

    private onChange = () => {
        if (this.enabled) this.renderLegend();
        else {
            const legendContainer = this.map.getPopoverContainer("bottom").querySelector("#traffic-legend");
            if (legendContainer) legendContainer.remove();
        }
    };

    getHTMLPopUp(properties: MapGeoJSONFeature[ "properties" ]) {
        const descriptionIdx = Object.keys(properties).find(k => k.match(/short/gi));
        const description = properties[ descriptionIdx ];
        return `
            <div class="nmapsgl-trafffic-events-popup">
                <div class="nmapsgl-popup-wrapper">
                    <span style="font-weight: 700">${properties.type}</span><span style="font-weight: 600; margin-left: 3px">(${properties.severity})</span>
                </div>
                ${description && `<div class="nmapsgl-popup-wrapper"><p class="nmapsgl-popup-description">${description}</p></div>`}
            </div>
        `;
    }

    renderLegend = () => {
        const popoverContainer = this.map.getPopoverContainer("bottom");
        const trafficLegendContainer = popoverContainer.querySelector("#traffic-legend");
        if (trafficLegendContainer) trafficLegendContainer.remove();
        const legend = html`
            <div id="traffic-legend" class="nmapsgl-control-scale traffic-scale">
                <div class="title">Transit</div>
                <div style="height: 15px"> 
                    <div class="road-closure">
                        <div class="road-closure-icon"></div>
                        <div>Road closure</div>
                    </div>
                </div>
                <div class="traffic-legend-wrapper"> 
                    ${this.legend.map(d => html`
                        <div>
                            <div class="square" style="background: ${d.background}"></div>
                            <div>${d.label}</div>
                        </div>
                    `)}
                </div>
            </div>
        `;
        popoverContainer.append(legend);
    };
}
