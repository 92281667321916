import { getJSON } from "maplibre-gl/src/util/ajax";
import { objectToUrlParams } from "~/utils";

export type SearchImageResponse = {
    data: [
        { id: string }
    ]
};

export async function searchStreetViewImage(
    params, authorization: string, abortController: AbortController
) {
    const queryParams = objectToUrlParams(params);
    const { data } = await getJSON<SearchImageResponse>({
        url: `https://graph.mapillary.com/images?${queryParams}`,
        headers: { Authorization: `OAuth ${authorization}` }
    }, abortController);
    return data;
}

export type ImageResponse = {
    id: string,
    sequence: string,
    thumb_256_url: string,
    compass_angle: number,
    geometry: {
        type: string,
        coordinates: number[]
    }
};

export async function getStreetViewImage(
    image_id, params, authorization: string, abortController: AbortController
) {
    const queryParams = objectToUrlParams(params);
    const { data } = await getJSON<ImageResponse>({
        url: `https://graph.mapillary.com/${image_id}?${queryParams}`,
        headers: { Authorization: `OAuth ${authorization}` }
    }, abortController);
    return data;
}
