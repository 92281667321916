import type { LayerSpecification, LngLatLike, Map, SourceSpecification } from "maplibre-gl";
import type { IShape } from "./shape";

import { LngLat } from "maplibre-gl";
import { extend } from "maplibre-gl/src/util/util";
import { uniqueId } from "~/utils";

type PolygonOptions = {
    strokeColor?: string,
    strokeOpacity?: number,
    strokeWeight?: number,
    fillColor?: string,
    fillOpacity: number
};

const defaultOptions: PolygonOptions = {
    strokeColor: "#000000",
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: "#000000",
    fillOpacity: 0.5
};

function validateOptions(options: PolygonOptions) {
    if (options.strokeOpacity < 0 || options.strokeOpacity > 1) throw Error("Invalid stroke opacity");
    if (options.strokeWeight < 0) throw Error("Invalid stroke weight");
    if (options.fillOpacity < 0 || options.fillOpacity > 1) throw Error("Invalid fill opacity");
    return true;
}

/**
 * Creates a polygon component.
 * @param {Object} [options]
 * @param {string} [options.strokeColor] The color used for the polygon outline. The default is black (#000000).
 * @param {number} [options.strokeOpacity] Specifies a numerical value between 0.0 and 1.0 to determine the opacity of the outline's color. The default is 1.0.
 * @param {number} [options.strokeWeight] The width of the outline in pixels. The default is 3.
 * @param {string} [options.fillColor] The color used to fill the polygon area. The default is black (#000000).
 * @param {number} [options.fillOpacity]  Specifies a numerical value between 0.0 and 1.0 to determine the opacity of the polygon's color area. The default is 0.5.
 * @example
 * const coordinates = [
 *   [-67.13734, 45.13745],
 *   [-70.11617, 43.68405],
 *   [-70.98176, 43.36789],
 *   [-70.30495, 45.91479],
 *   [-68.2343, 47.35462],
 *   [-67.13734, 45.13745]
 * ];
 *
 * let polygon = new nmapsgl.Polygon()
 *   .setPath(coordinates)
 *   .addTo(map);
 * @example
 * // Set options
 * let polygon = new nmapsgl.Polygon({
 *      strokeColor: "#FF0000",
 *      strokeOpacity: 0.8,
 *      strokeWeight: 2,
 *      fillColor: "#FF0000",
 *      fillOpacity: 0.35,
 *  }).setPath(coordinates)
 *  .addTo(map);
 */
export class Polygon implements IShape {
    _map: Map;
    _polygonID: string;
    _outlineID: string;
    _pathLnglat: LngLat[];
    private _options: PolygonOptions;

    constructor(options: PolygonOptions) {
        this._options = extend({}, defaultOptions, options);
        validateOptions(this._options);
    }

    /**
     * Get the polygon's geographical positions.
     *
     * @returns {LngLat[]} A {@link LngLat} array with the points that form the polygon.
     * @example
     * // Store the polygon points (longitude and latitude coordinates) in a variable
     * const points = polygon.getPath();
     * // Print the point's longitude and latitude values in the console
     * points.forEach((item) => {
     *   console.log('Longitude: ' + item.lng + ', Latitude: ' + item.lat );
     * });
     */
    getPath(): LngLat[] {
        return this._pathLnglat;
    }

    /**
     * Set the polygon's geographical positions.
     * @param {LngLatLike[]} path A {@link LngLat} array with the points that form the polygon.
     * @returns {Polygon} `this`
     * @example
     * // Create a new polygon, set the points, and add it to the map.
     * const coordinates = [
     *   [-67.13734, 45.13745],
     *   [-70.11617, 43.68405],
     *   [-70.98176, 43.36789],
     *   [-70.30495, 45.91479],
     *   [-68.2343, 47.35462],
     *   [-67.13734, 45.13745]
     * ];
     *
     * let polygon = new nmapsgl.Polygon()
     *   .setPath(coordinates)
     *   .addTo(map);
     */
    setPath(path: LngLatLike[]): this {
        this._pathLnglat = path.map(e => LngLat.convert(e));
        return this;
    }

    /**
     * Attaches the `Polygon` to a `Map` object.
     * @param {Map} map The NMaps GL JS map to add the polygon to.
     * @returns {Polygon} `this`
     * @example
     * const coordinates = [
     *   [-67.13734, 45.13745],
     *   [-70.11617, 43.68405],
     *   [-70.98176, 43.36789],
     *   [-70.30495, 45.91479],
     *   [-68.2343, 47.35462],
     *   [-67.13734, 45.13745]
     * ];
     *
     * let polygon = new nmapsgl.Polygon({
     *     strokeColor: "#FF0000",
     *     strokeOpacity: 0.8,
     *     strokeWeight: 2,
     *     fillColor: "#FF0000",
     *     fillOpacity: 0.35,
     *   }).setPath(coordinates)
     *   .addTo(map);
     */
    addTo(map: Map): this {
        this.remove();
        this._map = map;
        // Create polygon and outline ID
        this._polygonID = uniqueId("polygon");
        this._outlineID = uniqueId("polygon-line");

        // Generate Source and Layer to polygon
        this._map.on("styledata", this._addSource);

        return this;
    }

    _addSource = () => {
        const hasSource = !!this._map.getSource(this._polygonID);
        if (hasSource) return;

        const polygonSource: SourceSpecification = {
            "type": "geojson",
            "data": {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [ this._pathLnglat.map(e => e.toArray()) ]
                },
                "properties": {}
            }
        };
        const layer: LayerSpecification = {
            "id": this._polygonID,
            "type": "fill",
            "source": this._polygonID,
            "layout": {},
            "paint": {
                "fill-color": this._options.fillColor,
                "fill-opacity": this._options.fillOpacity
            }
        };

        const outline: LayerSpecification = {
            "id": this._outlineID,
            "type": "line",
            "source": this._polygonID,
            "layout": {},
            "paint": {
                "line-color": this._options.strokeColor,
                "line-width": this._options.strokeWeight,
                "line-opacity": this._options.strokeOpacity
            }
        };

        this._map.addSource(this._polygonID, polygonSource);
        this._map.addLayer(layer);
        this._map.addLayer(outline);
    };

    /**
     * Removes the polygon from a map.
     * @example
     * const coordinates = [
     *   [-67.13734, 45.13745],
     *   [-70.11617, 43.68405],
     *   [-70.98176, 43.36789],
     *   [-70.30495, 45.91479],
     *   [-68.2343, 47.35462],
     *   [-67.13734, 45.13745]
     * ];
     *
     * let polygon = new nmapsgl.Polygon()
     *   .setPath(coordinates)
     *   .addTo(map);
     *
     * polygon.remove();
     * @returns {Polygon} `this`
     */
    remove(): this {
        if (this._map) {
            if (this._map.getLayer(this._polygonID)) this._map.removeLayer(this._polygonID);
            if (this._map.getLayer(this._outlineID)) this._map.removeLayer(this._outlineID);
            this._map.removeSource(this._polygonID);

            this._map.off("styledata", this._addSource);

            delete this._map;
        }
        return this;
    }
}
