import type { SequenceComponent, ViewerImageEvent } from "mapillary-js";
import type { MapLayerMouseEvent } from "maplibre-gl";
import type { Map } from "../map";
import type { LayerFilter, LayerInstanceOptions } from "./layer_instance";

import { Viewer } from "mapillary-js";
import { Popup } from "maplibre-gl";
import { MapillaryService } from "~/services";
import { Marker } from "~/ui/marker";
import { html } from "~/utils";
import { LayerInstance } from "./layer_instance";

export type StreetViewLayerOptions = {
    mapillaryAccessToken: string
};

export class StreetViewLayer extends LayerInstance<StreetViewLayerOptions> {
    static readonly id = "nmapsgl_street_view";
    static readonly title = "Street view";

    private popup: Popup;
    private viewer: Viewer;
    private selectedSequence: string;
    private selectedMarker: Marker;
    private streetViewWindowEl: HTMLElement;
    private switchEl: HTMLElement;
    private closeEl: HTMLElement;

    constructor(options?: StreetViewLayerOptions & LayerInstanceOptions) {
        const filters: LayerFilter[] = [
            {
                id: "street_view",
                filter: l => l[ "source" ] === "mapillary",
            }
        ];
        super(filters, options);
    }

    onAdd(map: Map): void {
        const { mapillaryAccessToken } = this.options;

        if (!map.getSource("mapillary")) {
            map.addSource("mapillary", {
                "type": "vector",
                "tiles": [
                    `https://tiles.mapillary.com/maps/vtp/mly1_public/2/{z}/{x}/{y}?access_token=${mapillaryAccessToken}`
                ],
                "minzoom": 6,
                "maxzoom": 14
            });
        }
        if (!map.getLayer("mapillary-sequence")) {
            map.addLayer({
                "id": "mapillary-sequence",
                "type": "line",
                "source": "mapillary",
                "source-layer": "sequence",
                "layout": {
                    "line-cap": "round",
                    "line-join": "round",
                    "visibility": "none"
                },
                "paint": {
                    "line-opacity": 0.6,
                    "line-color": "rgb(18, 175, 166)",
                    "line-width": 2
                }
            });
        }
        if (!map.getLayer("mapillary-image")) {
            map.addLayer({
                "id": "mapillary-image",
                "type": "circle",
                "source": "mapillary",
                "source-layer": "image",
                "layout": {
                    "visibility": "none"
                },
                "paint": {
                    "circle-color": "rgb(18, 175, 166)",
                    "circle-radius": 5
                }
            });
        }
        super.onAdd(map);
        this.streetViewWindowEl = this.map.getContainer().appendChild(html`
            <div id="nmapsgl-streetview-mappilary-window" class="nmapsgl-streetview-minimize">
                <div class="nmapsgl-streetview-mappilary-container">
                    <div id="nmapsgl-streetview-loading-wrapper">
                        <div class="nmapsgl-streetview-image-loader nmapsgl-icon-loading"></div>
                    </div>
                    <div id="nmapsgl-streetviewer-mappilary-container"></div>
                </div>
                <div id="nmapsgl-streetview-mappilary-wrapper-close" onclick=${this.onCloseClick}></div>
                <div id="nmapsgl-streetview-mappilary-wrapper-switch" onclick=${this.onSwitchClick}></div>
            </div>
        `);

        const mapillaryContainer = document.getElementById("nmapsgl-streetviewer-mappilary-container");
        this.closeEl = document.getElementById("nmapsgl-streetview-mappilary-wrapper-close");
        this.switchEl = document.getElementById("nmapsgl-streetview-mappilary-wrapper-switch");

        this.viewer = new Viewer({
            accessToken: mapillaryAccessToken,
            container: mapillaryContainer,
        });

        this.map.on("mouseenter", "mapillary-image", this.onMapillaryImageMouseEnter);
        this.map.on("mouseleave", "mapillary-image", this.onMapillaryImageMouseLeave);
        this.map.on("mouseenter", "mapillary-sequence", this.onMapillarySequenceMouseEnter);
        this.map.on("mouseleave", "mapillary-sequence", this.onMapillarySequenceMouseLeave);
        this.map.on("click", "mapillary-image", this.onMapillaryImageClick);
        this.map.on("click", "mapillary-sequence", this.onMapillarySequenceClick);
        this.viewer.on("image", this.onImageViewer);
        this.on("hide", this.onHide);
    }

    onRemove(): void {
        this.map.off("mouseenter", "mapillary-image", this.onMapillaryImageMouseEnter);
        this.map.off("mouseleave", "mapillary-image", this.onMapillaryImageMouseLeave);
        this.map.off("mouseenter", "mapillary-sequence", this.onMapillarySequenceMouseEnter);
        this.map.off("mouseleave", "mapillary-sequence", this.onMapillarySequenceMouseLeave);
        this.map.off("click", "mapillary-image", this.onMapillaryImageClick);
        this.map.off("click", "mapillary-sequence", this.onMapillarySequenceClick);
        this.viewer.off("image", this.onImageViewer);
        this.off("hide", this.onHide);
        super.onRemove();
    }

    private onHide = () => {
        this.onCloseClick();
    };

    private onMapillaryImageMouseEnter = (e: MapLayerMouseEvent) => {
        this.map.getCanvas().style.cursor = "pointer";

        const { geometry, properties } = e.features[ 0 ];
        const coordinates = (geometry as GeoJSON.Point).coordinates.slice() as [ number, number ];

        // Ensure that if the map is zoomed out such that multiple copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[ 0 ]) > 180) {
            coordinates[ 0 ] += e.lngLat.lng > coordinates[ 0 ] ? 360 : -360;
        }

        this.popup = new Popup({ closeButton: false, closeOnClick: false, className: "nmapsgl-streetview-wrapper" })
            .setLngLat(coordinates)
            .setDOMContent(html`
                <div class="nmapsgl-streetview-image-loader nmapsgl-icon-loading"></div>
            `)
            .addTo(this.map);

        this.getImage(properties.id).then(({ thumb_256_url }) => {
            if (!this.popup) return;
            this.popup.setDOMContent(html`
                <img class="nmapsgl-streetview-image" src="${thumb_256_url}"/>
            `);
        });
    };

    private onMapillaryImageMouseLeave = (e: MapLayerMouseEvent) => {
        this.map.getCanvas().style.cursor = "";
        if (this.popup) {
            this.popup.remove();
            delete this.popup;
        }
    };

    private onMapillaryImageClick = (e: MapLayerMouseEvent) => {
        const { sequence_id, compass_angle } = e.features[ 0 ].properties;
        const geometry = e.features[ 0 ].geometry as GeoJSON.Point;
        const coordinates = geometry.coordinates as [ number, number ];

        if (this.popup) {
            this.popup.remove();
            delete this.popup;
        }
        if (this.selectedMarker) {
            this.selectedMarker.remove();
            delete this.selectedMarker;
        }
        this.resetHighlightedSequence();
        this.highlightSequence(sequence_id);
        this.highlightPoint({ compass_angle, coordinates });
        this.setWindowOpen(true);

        const loadingEl = document.getElementById("nmapsgl-streetview-loading-wrapper");
        if (loadingEl) loadingEl.classList.add("nmapsgl-streetview-loading-wrapper-active");

        this.viewer.moveTo(e.features[ 0 ].properties.id)
            .finally(() => {
                if (loadingEl) loadingEl.classList.remove("nmapsgl-streetview-loading-wrapper-active");
            });
    };

    private onMapillarySequenceMouseEnter = (e: MapLayerMouseEvent) => {
        if (this.map.getZoom() > 14) return;

        this.map.getCanvas().style.cursor = "pointer";

        const { properties } = e.features[ 0 ];

        this.popup = new Popup({ closeButton: false, closeOnClick: false, className: "nmapsgl-streetview-wrapper" })
            .setLngLat(e.lngLat)
            .setDOMContent(html`
                <div class="nmapsgl-streetview-image-loader nmapsgl-icon-loading"></div>
            `)
            .addTo(this.map);

        this.getImage(properties.image_id).then(({ thumb_256_url }) => {
            if (!this.popup) return;
            this.popup.setDOMContent(html`
                    <img class="nmapsgl-streetview-image" src="${thumb_256_url}"/>
                `);
        });
    };

    private onMapillarySequenceMouseLeave = (e: MapLayerMouseEvent) => {
        if (this.map.getZoom() > 14) return;
        this.map.getCanvas().style.cursor = "";
        if (this.popup) {
            this.popup.remove();
            delete this.popup;
        }
    };

    private onMapillarySequenceClick = (e: MapLayerMouseEvent) => {
        if (this.map.getZoom() > 14) return;

        const { image_id, id } = e.features[ 0 ].properties;

        if (this.popup) {
            this.popup.remove();
            delete this.popup;
        }
        if (this.selectedMarker) {
            this.selectedMarker.remove();
            delete this.selectedMarker;
        }
        this.resetHighlightedSequence();
        this.highlightSequence(id);

        this.getImage(image_id)
            .then(({ compass_angle, geometry }) => {
                this.highlightPoint({ compass_angle, coordinates: geometry.coordinates });
            });

        this.setWindowOpen(true);

        const loadingEl = document.getElementById("nmapsgl-streetview-loading-wrapper");
        if (loadingEl) loadingEl.classList.add("nmapsgl-streetview-loading-wrapper-active");

        this.viewer.moveTo(image_id)
            .finally(() => {
                if (loadingEl) loadingEl.classList.remove("nmapsgl-streetview-loading-wrapper-active");
            });
    };

    private getImage(imageID: string) {
        const params = { "fields": "id,geometry,compass_angle,thumb_256_url" };
        return MapillaryService.getStreetViewImage(imageID, params, this.options.mapillaryAccessToken, new AbortController());
    }

    private highlightSequence = (sequenceId: string) => {
        this.selectedSequence = sequenceId;
        // Highlight sequence
        this.map.setFilter("mapillary-sequence", [ "==", "id", this.selectedSequence ]);
        this.map.setPaintProperty("mapillary-sequence", "line-color", "rgb(130,215,210)");

        // Highlight circles
        this.map.setFilter("mapillary-image", [ "==", "sequence_id", this.selectedSequence ]);
        this.map.setPaintProperty("mapillary-image", "circle-color", "rgb(130,215,210)");
    };

    private resetHighlightedSequence() {
        if (this.map.getLayer("mapillary-sequence")) {
            this.map.setFilter("mapillary-sequence", null);
            this.map.setPaintProperty("mapillary-sequence", "line-color", "rgb(18, 175, 166)");
        }
        if (this.map.getLayer("mapillary-image")) {
            this.map.setFilter("mapillary-image", null);
            this.map.setPaintProperty("mapillary-image", "circle-color", "rgb(18, 175, 166)");
        }
    }

    private highlightPoint = ({ coordinates, compass_angle }) => {
        const el = html`
            <div class="nmapsgl-image-selected">
                <div id="nmapsgl-image-selected-circle" style="transform: rotate(${compass_angle}deg)">
                    <div class="sector1"></div>
                    <div class="round-middle"></div>
                </div>
            <div>
        `;
        this.selectedMarker = new Marker({ element: el })
            .setLngLat(coordinates)
            .addTo(this.map);

        this.map.flyTo({ center: coordinates });
    };

    private setWindowOpen = (value: boolean) => {
        const sequenceComponent: SequenceComponent = this.viewer.getComponent("sequence");
        sequenceComponent.stop();
        if (value) {
            this.closeEl.style.display = "block";
            this.streetViewWindowEl.classList.remove("nmapsgl-streetview-minimize");
        } else {
            this.closeEl.style.display = "none";
            this.streetViewWindowEl.classList.add("nmapsgl-streetview-minimize");
        }
    };

    private onImageViewer = (e: ViewerImageEvent) => {
        const { originalLngLat, compassAngle, sequenceId } = e.image;
        this.selectedMarker.setLngLat(originalLngLat);

        const circleEl = document.getElementById("nmapsgl-image-selected-circle");
        if (circleEl) {
            circleEl.style.transform = `rotate(${compassAngle}deg)`;
        }

        if (e.image.sequenceId !== this.selectedSequence) {
            this.highlightSequence(sequenceId);
        }

        const isInsideBounds = this.map.getBounds().contains(this.selectedMarker.getLngLat());
        if (!isInsideBounds) {
            this.map.jumpTo({ center: originalLngLat });
        }
    };

    private onCloseClick = () => {
        const sequenceComponent: SequenceComponent = this.viewer.getComponent("sequence");
        sequenceComponent.stop();
        this.setWindowOpen(false);
        this.resetHighlightedSequence();
        this.streetViewWindowEl.classList.remove("nmapsgl-streetview-mappilary-window-full");
        this.switchEl.classList.remove("nmapsgl-streetview-mappilary-wrapper-switch-active");
        if (this.selectedMarker) {
            this.selectedMarker.remove();
            delete this.selectedMarker;
        }
    };

    private onSwitchClick = (e: Event) => {
        this.streetViewWindowEl.classList.toggle("nmapsgl-streetview-mappilary-window-full");
        this.switchEl.classList.toggle("nmapsgl-streetview-mappilary-wrapper-switch-active");
    };

}
