import type { ControlPosition, IControl } from "maplibre-gl";
import type { Map } from "~/ui/map";

import { addTooltip, controlButton, controlContainer } from "~/utils";
import { TrafficLayer } from "../layers";

/**
 * A `TrafficControl` control provides a toogle button to activate/deactivate traffic layers on the map.
 *
 * @title Traffic
 * @implements {IControl}
 * @param {Object} options
 * @param {boolean} [options.showTraffic=false] - Show or hide traffic layers.
 * @param {Object} [options.showTrafficButton=true] - Show a toggle button to turn traffic on and off.
 * @param {string} [options.units='Metric'] Unit of the distance (`'Metric'` or `'Imperial'`).
 * @param {string} [options.interaction='click'] Mouse interaction in traffic events layer (`'click'` or `'hover'`).
 * @example
 * map.addControl(new nmapsgl.TrafficControl({
 *      showTraffic: 'true',
 *      unit: 'Metric',
 *      interaction: 'hover'
 * }));
 */
export class TrafficControl implements IControl {

    private map: Map;
    private container: HTMLElement;
    private ctrlButton: HTMLElement;

    onAdd(map: Map): HTMLElement {
        this.map = map;
        const instance = this.map.addLayerInstance(TrafficLayer);
        this.container = controlContainer();
        this.ctrlButton = this.container.appendChild(controlButton({
            title: TrafficLayer.title,
            className: "nmapsgl-ctrl-traffic",
            onClick: this.onCtrlClick
        }));
        addTooltip(this.ctrlButton);

        if (instance.isEnabled()) this.ctrlButton.classList.add("active");
        instance.on("show", this.onShow);
        instance.on("hide", this.onHide);
        return this.container;
    }

    onRemove(): void {
        const instance = this.map.getLayerInstance(TrafficLayer.id);
        instance.off("show", this.onShow);
        instance.off("hide", this.onHide);
        delete this.map;
    }

    getDefaultPosition?: () => ControlPosition = () => "top-right";

    private onCtrlClick = () => {
        const instance = this.map.getLayerInstance(TrafficLayer.id);
        instance.setState(!instance.isEnabled());
    };

    private onShow = () => {
        this.ctrlButton.classList.add("active");
    };

    private onHide = () => {
        this.ctrlButton.classList.remove("active");
    };
}
