import type { Map } from "maplibre-gl";

import { FullscreenControl as _FullscreenControl } from "maplibre-gl";
import { addTooltip } from "~/utils";

export class FullscreenControl extends _FullscreenControl {

    onAdd(map: Map): HTMLElement {
        const result = super.onAdd(map);
        addTooltip(this._fullscreenButton, "Fullscreen");
        return result;
    }
}
