import { addProtocol, config } from "maplibre-gl";
import "~/css/style.css";
import { Map, Marker } from "~/ui";
import {
    DirectionControl,
    FullscreenControl, GeocoderControl, GeolocateControl, LayersControl,
    LogoControl, NavigationControl, RotateControl,
    RoutingControl,
    RulerControl,
    ScaleControl,
    StreetViewControl, StyleControl, TrafficControl
} from "~/ui/control";
import { Circle, Polygon, Polyline, Rectangle } from "~/ui/shapes";
import { normalizeProtocol } from "~/utils";
import { makeNMapsRequest } from "./api";

function getAccessToken(): string | undefined {
    return config.ACCESS_TOKEN;
}

function setAccessToken(value: string) {
    config.ACCESS_TOKEN = value;
}

function getApiUrl(): string {
    return config.API_URL || "https://nmaps.ndrive.com/api";
}

function setApiUrl(value: string) {
    config.API_URL = value;
}

addProtocol("nmaps", (params, abortController) => {
    const isJSON = params.url.endsWith(".json");
    const urlParts = params.url.split("://");
    params.url = `https://${urlParts[ 1 ]}`;
    return makeNMapsRequest(params, abortController)
        .then((res) => {
            if (isJSON) res.data = normalizeProtocol(res.data);
            return res;
        });
});

export {
    addProtocol, addSourceType, AJAXError, AttributionControl, BoxZoomHandler, CanvasSource, clearPrewarmedResources, config, CooperativeGesturesHandler, DoubleClickZoomHandler, DragPanHandler, DragRotateHandler, EdgeInsets, Evented, GeoJSONSource, getMaxParallelImageRequests, getRTLTextPluginStatus, getVersion, getWorkerCount, getWorkerUrl, Hash, ImageSource, importScriptInWorkers, KeyboardHandler, LngLat, LngLatBounds, MapMouseEvent, MapTouchEvent, MapWheelEvent, MercatorCoordinate, Point, Popup, prewarm, RasterDEMTileSource, RasterTileSource, removeProtocol, ScrollZoomHandler, setMaxParallelImageRequests, setRTLTextPlugin, setWorkerCount, setWorkerUrl, Style, TerrainControl, TwoFingersTouchPitchHandler, TwoFingersTouchRotateHandler, TwoFingersTouchZoomHandler, TwoFingersTouchZoomRotateHandler, VectorTileSource, VideoSource
} from "maplibre-gl";

export {
    Circle, DirectionControl, FullscreenControl,
    GeocoderControl,
    GeolocateControl, getAccessToken,
    getApiUrl, LayersControl,
    LogoControl,
    Map,
    Marker,
    NavigationControl,
    Polygon,
    Polyline,
    Rectangle,
    RotateControl, RoutingControl, RulerControl, ScaleControl, setAccessToken,
    setApiUrl, StreetViewControl,
    StyleControl,
    TrafficControl
};
