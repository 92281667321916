import type { LayerSpecification, Listener } from "maplibre-gl";
import type { Map } from "~/ui/map";

import { extend } from "maplibre-gl/src/util/util";

export type LayerFilter = {
    id: string;
    label?: string;
    filter?: ((layer: LayerSpecification) => boolean);
};

export type LayerInstanceOptions = {
    initialState?: Record<string, boolean>,
    initialEnabled?: boolean
};

export abstract class LayerInstance<T = object> {
    static readonly id: string;
    static readonly title: string;

    protected map: Map;
    protected eventTarget = new EventTarget();
    protected enabled: boolean;
    protected state: Record<string, boolean>;
    options?: T & LayerInstanceOptions;
    filters: LayerFilter[] = [];

    constructor(filters: LayerFilter[], options?: T & LayerInstanceOptions) {
        this.filters = filters;
        const defaultOptions: LayerInstanceOptions = {
            initialEnabled: false,
            initialState: Object.fromEntries(this.filters.map(f => ([ f.id, false ])))
        };
        this.options = extend({}, defaultOptions, options);
        if (!options?.initialState) {
            Object.keys(this.options.initialState)
                .forEach(is_key => this.options.initialState[ is_key ] = this.options.initialEnabled);
        }
        this.enabled = this.options.initialEnabled;
        this.state = this.options.initialState;
    }

    get computedValue(): boolean {
        return !!this.enabled && Object.values(this.state).some(Boolean);
    }

    onAdd(map: Map): void {
        this.map = map;
        const layerIds = Object.values(this.computeLayerIds()).flat();
        this.map.persistLayers(layerIds);
        this.applyState();
        if (this.computedValue) this.eventTarget.dispatchEvent(new Event("show"));
    }

    onRemove(): void {
        const layerIds = Object.values(this.computeLayerIds()).flat();
        this.map.removePersistentLayers(layerIds);
        delete this.map;
    }

    setState(enabled: boolean, values: Record<string, boolean> = {}): void {
        const previousComputedValue = this.computedValue;
        this.enabled = enabled;
        const newState = Object.fromEntries(this.filters.map(f => ([
            f.id,
            f.id in values ? values[ f.id ] : enabled
        ])));
        this.state = newState;
        const newComputedValue = this.computedValue;
        this.applyState();
        this.eventTarget.dispatchEvent(new Event("change"));
        if (previousComputedValue === newComputedValue) return;
        if (newComputedValue) this.eventTarget.dispatchEvent(new Event("show"));
        else this.eventTarget.dispatchEvent(new Event("hide"));
    }

    private applyState() {
        const layerIds = this.computeLayerIds();
        if (!this.enabled) {
            // Hide all layers
            this.setLayersVisibility(Object.values(layerIds).flat(), false);
        } else {
            const visible = [];
            const hidden = [];
            Object.entries(this.state).forEach(([ k, v ]) => {
                if (v) visible.push(...layerIds[ k ]);
                else hidden.push(...layerIds[ k ]);
            });
            if (visible.length) this.setLayersVisibility(visible, true);
            if (hidden.length) this.setLayersVisibility(hidden, false);
        }
    }

    isFilterEnabled(id: string): boolean {
        return this.state[ id ];
    }

    isEnabled(): boolean {
        return this.enabled;
    }

    on(type: "show" | "hide" | "change", listener: Listener): void {
        this.eventTarget.addEventListener(type, listener);
    }

    off(type: "show" | "hide" | "change", listener: Listener): void {
        this.eventTarget.removeEventListener(type, listener);
    }

    computeLayerIds(): Record<string, string[]> {
        const result = {};
        const styleLayers = this.map.getStyle()?.layers || [];
        this.filters.forEach((e) => {
            result[ e.id ] = styleLayers.filter(e.filter).map(e => e.id);
        });
        return result;
    }

    private setLayersVisibility(layerIds: string[], value: boolean) {
        const visibility = value ? "visible" : "none";
        layerIds.forEach((id) => {
            if (!this.map.getLayer(id)) return;
            this.map.setLayoutProperty(id, "visibility", visibility);
        });
    }
}
