import type { MapStyle } from "~/ui/control/style_control";

import { config } from ".";

export const assets_url = "https://cdn.ndrive.com/nmapsgl/assets";
export const dark_img = `${assets_url}/dark.png`;
export const default_day_img = `${assets_url}/default_day.png`;
export const light_img = `${assets_url}/light.png`;
export const satellite_img = `${assets_url}/satellite.png`;
export const plane_img = `${assets_url}/plane.png`;
export const marker_img = `${assets_url}/marker.png`;
export const waypoint_img = `${assets_url}/waypoint.png`;
export const waypoint_marker_img = `${assets_url}/waypoint_marker.png`;

// Colors
export const markerColor = "#F05123";

// Styles
const defaultStyles: Record<"STREET_STYLE" | "LIGHT_STYLE" | "DARK_STYLE" | "SATELLITE_STYLE", MapStyle> = {
    STREET_STYLE: {
        label: "Streets",
        styleName: "default_day",
        styleUrl: "/tile/styles/default_day.json",
        imageUrl: default_day_img
    },
    LIGHT_STYLE: {
        label: "Light",
        styleName: "light",
        styleUrl: "/tile/styles/default_light.json",
        imageUrl: light_img
    },
    DARK_STYLE: {
        label: "Dark",
        styleName: "dark",
        styleUrl: "/tile/styles/default_dark.json",
        imageUrl: dark_img
    },
    SATELLITE_STYLE: {
        label: "Satellite",
        styleName: "satellite",
        styleUrl: "/tile/styles/default_satellite.json",
        imageUrl: satellite_img
    }
};

export const getDefaultStyle: (string) => MapStyle = (styleName) => {
    const style = { ...defaultStyles[ styleName ] };
    if (style && config.API_URL) {
        const api_url = config.API_URL.replace("https://", ""); //TODO REGEX
        style.styleUrl = `nmaps://${api_url}${style.styleUrl}`;
    }
    return style;
};

export const getDefaultStyles: () => MapStyle[] = () => Object.keys(defaultStyles).map(key => getDefaultStyle(key));

// Traffic TODO: remove this
export const trafficSourceName = "ndrive-traffic";
export const speedLayers = {
    "Metric": /traffic_speed_\w+_kph.*/,
    "Imperial": /traffic_speed_\w+_mph.*/
};

// Geocoder
export const geocoderExcludedkeys = [ "ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight" ];

// Directions
export const directionsSourceName = "directions";
