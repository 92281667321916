import type { LayerSpecification, LngLatLike, Map, SourceSpecification } from "maplibre-gl";
import type { IShape } from "./shape";

import { LngLat } from "maplibre-gl";
import { extend } from "maplibre-gl/src/util/util";
import { uniqueId } from "~/utils";

type CircleOptions = {
    strokeColor?: string,
    strokeOpacity?: number,
    strokeWeight?: number,
    fillColor?: string,
    fillOpacity?: number,
    radius?: number
};

const defaultOptions: CircleOptions = {
    strokeColor: "#000000",
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: "#000000",
    fillOpacity: 0.5,
    radius: 5
};

function validateOptions(options: CircleOptions) {
    if (options.strokeOpacity < 0 || options.strokeOpacity > 1) throw Error("Invalid stroke opacity");
    if (options.strokeWeight < 0) throw Error("Invalid stroke weight");
    if (options.fillOpacity < 0 || options.fillOpacity > 1) throw Error("Invalid fill opacity");
    return true;
}

/**
 * Creates a circle component.
 * @param {Object} [options]
 * @param {number} [options.radius]  Defines the circle radius in pixels. The default is 5.
 * @param {string} [options.strokeColor] The color used for the circle outline. The default is black (#000000).
 * @param {number} [options.strokeOpacity] Specifies a numerical value between 0.0 and 1.0 to determine the opacity of the outline's color. The default is 1.0.
 * @param {number} [options.strokeWeight] The width of the outline in pixels. The default is 3.
 * @param {string} [options.fillColor] The color used to fill the circle area. The default is black (#000000).
 * @param {number} [options.fillOpacity]  Specifies a numerical value between 0.0 and 1.0 to determine the opacity of the circle's color area. The default is 0.5.
 * @example
 * const center = [-116.243, 33.678];
 *
 * let circle = new nmapsgl.Circle()
 *   .setCenter(center)
 *   .addTo(map);
 * @example
 * // Set options
 * let circle = new nmapsgl.Circle({
 *     strokeColor: "#FF0000",
 *     strokeOpacity: 0.8,
 *     strokeWeight: 2,
 *     fillColor: "#FF0000",
 *     fillOpacity: 0.35,
 *     radius: 10
 *   }).setCenter(center)
 *   .addTo(map);
 */
export class Circle implements IShape {
    _map: Map;
    _circleID: string;
    _center: LngLat;
    private _options: CircleOptions;

    constructor(options: CircleOptions) {
        this._options = extend({}, defaultOptions, options);
        validateOptions(this._options);
    }

    /**
     * Get the circle's geographical center.
     *
     * @returns {LngLat} A {@link LngLat} with the center of the circle.
     * @example
     * // Store the circle center (longitude and latitude coordinates) in a variable
     * const center = circle.getCenter();
     * // Print the point longitude and latitude values in the console
     * console.log('Longitude: ' + center.lng + ', Latitude: ' + center.lat );
     */
    getCenter(): LngLat {
        return this._center;
    }

    /**
     * Set the circle's geographical center.
     * @param {LngLatLike} center A {@link LngLat} with the geographic center of the circle.
     * @returns {Circle} `this`
     * @example
     * // Create a new circle, set the center and add it to the map.
     * let circle = new nmapsgl.Circle()
     *   .setCenter([-116.243, 33.678])
     *   .addTo(map);
     */
    setCenter(center: LngLatLike): this {
        this._center = LngLat.convert(center);
        return this;
    }

    /**
     * Attaches the `Circle` to a `Map` object.
     * @param {Map} map The NMaps GL JS map to add the circle to.
     * @returns {Circle} `this`
     * @example
     * let circle = new nmapsgl.Circle()
     *   .setCenter([-116.243, 33.678])
     *   .addTo(map);
     */
    addTo(map: Map): this {
        this.remove();
        this._map = map;
        // Create polygon and outline ID
        this._circleID = uniqueId("circle");

        // Generate Source and Layer to polygon
        this._map.on("styledata", this._addSource);

        return this;
    }

    _addSource = () => {
        const hasSource = !!this._map.getSource(this._circleID);
        if (hasSource) return;

        const circleSource: SourceSpecification = {
            "type": "geojson",
            "data": {
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": this._center.toArray()
                },
                "properties": {}
            }
        };
        const layer: LayerSpecification = {
            "id": this._circleID,
            "type": "circle",
            "source": this._circleID,
            "layout": {},
            "paint": {
                "circle-stroke-color": this._options.strokeColor,
                "circle-stroke-opacity": this._options.strokeOpacity,
                "circle-stroke-width": this._options.strokeWeight,
                "circle-color": this._options.fillColor,
                "circle-opacity": this._options.fillOpacity,
                "circle-radius": this._options.radius
            }
        };

        this._map.addSource(this._circleID, circleSource);
        this._map.addLayer(layer);
    };

    /**
     * Removes the circle from a map.
     * @example
     * let circle = new nmapsgl.Circle()
     *   .setCenter([-116.243, 33.678])
     *   .addTo(map);
     *
     * circle.remove();
     * @returns {Circle} `this`
     */
    remove(): this {
        if (this._map) {
            if (this._map.getLayer(this._circleID)) this._map.removeLayer(this._circleID);
            this._map.removeSource(this._circleID);

            this._map.off("styledata", this._addSource);

            delete this._map;
        }
        return this;
    }
}
