
import type { Placement } from "@floating-ui/dom";
import type { DebouncedFunc } from "lodash";
import type { LngLatLike, MapLayerMouseEvent } from "maplibre-gl";
import type { Map } from "~/ui";

import { arrow, autoPlacement, autoUpdate, computePosition, flip, offset, shift } from "@floating-ui/dom";
import { distance, point } from "@turf/turf";
import { html as lighterhtml } from "lighterhtml";
import debounce from "lodash.debounce";
import { GeolocateControl } from "maplibre-gl";
import { DirectionControl, LayersControl, RoutingControl, RulerControl, StyleControl } from "~/ui/control";

export const html = lighterhtml.node;

export function normalizeProtocol(object) {
    for (const key in object) {
        if (object[ key ] != undefined && typeof object[ key ] === "object") {
            normalizeProtocol(object[ key ]);
        } else if (typeof object[ key ] === "string") {
            object[ key ] = object[ key ].replace(/https:\/\/(.*?)ndrive\.com/g, "nmaps://$1ndrive.com");
        }
    }
    return object;
}

export function objectToUrlParams(obj: any) {
    const params = [];
    for (const key in obj) {
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[ key ])}`);
    }
    return params.join("&");
}

export function isGeolocateControl(target: any): target is GeolocateControl {
    return target instanceof GeolocateControl;
}

export function isStyleControl(target: any): target is StyleControl {
    return target instanceof StyleControl;
}

export function isRulerControl(target: any): target is RulerControl {
    return target instanceof RulerControl;
}

export function isRoutingControl(target: any): target is RoutingControl {
    return target instanceof RoutingControl;
}

export function isLayersControl(target: any): target is LayersControl {
    return target instanceof LayersControl;
}

export function isDirectionsControl(target: any): target is DirectionControl {
    return target instanceof DirectionControl;
}

export function uniqueId(namespace: string, length = 10): string {
    const id = parseInt(Math.ceil(Math.random() * Date.now()).toPrecision(length).toString().replace(".", ""));
    return `__${namespace.toUpperCase()}__${id}`;
}

export function changeTimeZone(date: Date | string, timeZone: string): Date {
    const _date = (typeof date === "string") ? new Date(date) : date;
    return new Date(_date.toLocaleString("en-US", { timeZone }));
}

export function dateFormat(date: Date): string {
    return date.toLocaleString("en-US", { month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" });
}

/**
 * Create MapLibreGL control container
 * @param {string} className
 */
export function controlContainer(className?: string): HTMLDivElement {
    const container = document.createElement("div");
    container.classList.add("maplibregl-ctrl", "maplibregl-ctrl-group");
    if (className) container.classList.add(className);
    return container;
}

export function controlButton(options?: {
    title?: string;
    className: string;
    onClick?: ((event: Event) => void);
}) {
    return html`
        <button title="${options.title}" class="${options.className}" onclick="${options.onClick}">
            <span class="maplibregl-ctrl-icon" aria-hidden="true"></span>
        </button>
    `;
}

export type HTMLTooltipElement = HTMLElement & {
    setContent: (textContent: string) => void
};

export function addTooltip(target: HTMLElement, title?: string, placement?: Placement): HTMLTooltipElement {
    if (!target) return;

    const content = title || target.title || target.ariaLabel;
    target.removeAttribute("title");
    const tooltipEl = target.appendChild(html`
        <div class="nmapsgl-tooltip">
            <span id="nmapsgl-tooltip-content">${content}</span>
            <div id="nmapsgl-tooltip-arrow"></div>
        </div>
    `) as HTMLTooltipElement;

    target.onmouseenter = debounce(() => {
        tooltipEl.classList.add("active");
        target[ "cancelUpdatePosition" ] = setTooltipPosition(target, tooltipEl, placement);
    }, 1000);

    target.onmouseleave = () => {
        tooltipEl.classList.remove("active");
        (target.onmouseenter as DebouncedFunc<() => void>)?.cancel();
        target[ "cancelUpdatePosition" ]?.();
    };

    tooltipEl.setContent = (textContent) => {
        tooltipEl.querySelector("#nmapsgl-tooltip-content").textContent = textContent;
    };

    return tooltipEl;
}

export function setPopoverPosition(reference: HTMLElement, floating: HTMLElement): () => void {
    let cleanup: (() => void) | null = null;

    const update = () => {
        computePosition(reference, floating, {
            middleware: [
                offset(reference.clientWidth * -1),
                autoPlacement({ alignment: "start" }),
                shift({ padding: 10 })
            ]
        }).then(({ x, y }) => {
            floating.style.setProperty("left", `${x}px`);
            floating.style.setProperty("top", `${y}px`);
        });
    };

    function manageAutoUpdate() {
        // Rule to change to mobile version
        const isSmallScreen = isMobile();

        floating.classList.toggle("mobile", isSmallScreen);

        if (isSmallScreen) {
            if (cleanup) {
                cleanup();
                cleanup = null;
            }
            floating.style.removeProperty("top");
            floating.style.removeProperty("left");
        } else {
            if (!cleanup) cleanup = autoUpdate(reference, floating, update);
            update();
        }
    }

    manageAutoUpdate();

    const onResize = () => setTimeout(manageAutoUpdate, 100);
    window.addEventListener("resize", onResize);

    return () => {
        if (cleanup) cleanup();
        window.removeEventListener("resize", onResize);
    };
}

export function setTooltipPosition(reference: HTMLElement, floating: HTMLElement, placement?: Placement): () => void {
    const arrowEl: HTMLElement = floating.querySelector("#nmapsgl-tooltip-arrow");
    const arrowLen = arrowEl.offsetWidth;
    const update = () => {
        computePosition(reference, floating, {
            placement: placement || "left",
            middleware: [
                flip(),
                arrow({ element: arrowEl, padding: 4 }),
                offset(5)
            ]
        })
            .then(({ x, y, middlewareData, placement }) => {
                floating.style.setProperty("left", `${x}px`);
                floating.style.setProperty("top", `${y}px`);
                const side = placement.split("-")[ 0 ];
                const staticSide = {
                    top: "bottom",
                    right: "left",
                    bottom: "top",
                    left: "right"
                }[ side ];
                if (middlewareData.arrow && arrowEl) {
                    const { x: arrowX, y: arrowY } = middlewareData.arrow;
                    arrowEl.style.setProperty("left", arrowX != undefined ? `${arrowX}px` : "",);
                    arrowEl.style.setProperty("top", arrowY != undefined ? `${arrowY}px` : "");
                    arrowEl.style.setProperty("right", "");
                    arrowEl.style.setProperty("bottom", "");
                    arrowEl.style.setProperty(staticSide, `${-arrowLen / 2 + 1}px`);
                }
            });
    };

    return autoUpdate(reference, floating, update, { elementResize: false });
}

export function waitForDOMElement(selector: string, container: HTMLElement = document.documentElement): Promise<HTMLElement> {
    return new Promise((resolve) => {
        const elem = container.querySelector<HTMLElement>(selector);
        if (elem) {
            return resolve(elem);
        }

        const observer = new MutationObserver(() => {
            const elem = container.querySelector<HTMLElement>(selector);
            if (elem) {
                observer.disconnect();
                resolve(elem);
            }
        });

        observer.observe(container, {
            childList: true,
            subtree: true
        });
    });
}

export function onceLoaded(map: Map, callback) {
    const _callback = () => {
        map[ "actuallyLoaded" ] = true;
        callback();
    };
    if (map.loaded() || map[ "actuallyLoaded" ]) {
        _callback();
    } else if (!map.areTilesLoaded()) {
        map.once("data", _callback);
    } else if (!map.isStyleLoaded()) {
        map.once("styledata", _callback);
    } else {
        _callback();
    }
    return map;
}

export function normalizeMapMouseCoordinates(ev: MapLayerMouseEvent): LngLatLike {
    const { geometry } = ev.features[ 0 ] as GeoJSON.Feature<GeoJSON.Point>;
    const coordinates = geometry.coordinates.slice() as [ number, number ];
    while (Math.abs(ev.lngLat.lng - coordinates[ 0 ]) > 180) {
        coordinates[ 0 ] += ev.lngLat.lng > coordinates[ 0 ] ? 360 : -360;
    }
    return coordinates;
}

export function generateUniqueId() {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 10000);
    const uniqueId = timestamp.toString() + "-" + random.toString();
    return uniqueId;
}

export function getDistance(point1, point2, options) {
    const from = point(point1);
    const to = point(point2);
    return Math.floor(distance(from, to, options));
}

export function formatDistance(distance, units, full) {
    if (units === "Imperial") {
        const miles = distance * 0.000621371192;
        return miles < 1 ? `${miles.toFixed(2)} mile` : `${miles.toFixed(1)} miles`;
    } else {
        return distance > 1000 ? `${Math.round(distance / 1000)} ${full ? "kilometers" : "km"}` : `${Math.round(distance)} ${full ? "meters" : "m"}`;
    }
}

export function formatTime(time) {
    const treatedTime = Math.round(time / 60);
    if (time < 60) {
        return `${Math.round(time)} sec`;
    } else {
        return treatedTime > 60 ? `${Math.trunc(treatedTime / 60)} h ${treatedTime % 60} min` : `${treatedTime} min`;
    }
}

export function convertDistance(distance, short = false) {
    const distanceElem = distance.split(" ");
    const distanceValue = distanceElem[ 0 ];
    const distanceMeasure = distanceElem[ 1 ];
    if (distanceMeasure === "kilometers" || distanceMeasure === "km") {
        return `${(distanceValue * 0.621371).toFixed(1)} miles`;
    } else if (distanceMeasure === "meters" || distanceMeasure === "m") {
        return `${(distanceValue * 0.0006213712).toFixed(2)} mile`;
    } else if (distanceMeasure === "miles") {
        return `${(distanceValue * 1.60934).toFixed(0)} ${short ? "km" : "kilometers"}`;
    } else if (distanceMeasure === "mile") {
        return `${(distanceValue * 1609.34).toFixed(0)} ${short ? "m" : "meters"}`;
    } else {
        return distance;
    }
}

export function isMobile() {
    return window.innerWidth < 768 || window.innerHeight < 600;
}

export function addOverlay() {
    const overlay = document.getElementById("nmapsgl-popover-overlay");
    overlay.style.opacity = "0.3";
    overlay.style.display = "block";
    document.body.classList.add("overlay-active");
}

export function removeOverlay() {
    const overlay = document.getElementById("nmapsgl-popover-overlay");
    overlay.addEventListener("transitionend", function handler() {
        overlay.removeEventListener("transitionend", handler);
        overlay.style.display = "none";
    });
    overlay.style.opacity = "0";
    document.body.classList.remove("overlay-active");
}
