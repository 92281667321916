import type { MapLayerMouseEvent, Offset } from "maplibre-gl";
import type { DirectionControl } from "~/index";
import type { Map } from "~/ui/map";
import type { LayerFilter, LayerInstanceOptions } from "./layer_instance";

import { debounce } from "lodash";
import { Popup } from "maplibre-gl";
import { extend } from "maplibre-gl/src/util/util";
import { makeNMapsRequest } from "~/api";
import { getApiUrl } from "~/index";
import { html, isDirectionsControl } from "~/utils";
import { LayerInstance } from "./layer_instance";

const defaultOptions: LayerInstanceOptions = {
    initialEnabled: false
};

export class POIsLayer extends LayerInstance {
    static readonly id = "nmapsgl_pois";
    static readonly title = "POIs";

    private popup: Popup;
    private categories = [];
    private popupCloseTimeout = null;
    private directionsControl: DirectionControl = null;

    constructor(options?: LayerInstanceOptions) {
        options = extend({}, defaultOptions, options);
        const filters: LayerFilter[] = [
            {
                id: "pois",
                filter: l => l.id === "pois",
            }
        ];
        super(filters, options);
    }

    onAdd(map: Map): void {
        super.onAdd(map);
        this.getCategories();
        this.map.on("mouseenter", "pois", this.onPOIsMouseEnter);
        this.map.on("mouseenter", "pois", this.onPOIsMouseEnterDebounce);
        this.map.on("mouseleave", "pois", this.onPOIsMouseLeave);
        this.map.on("click", "pois", this.onPOIsClick);
        const control = this.map._controls.find(isDirectionsControl);
        if (control) this.directionsControl = control;
    }

    onRemove(): void {
        this.map.off("mouseenter", "pois", this.onPOIsMouseEnter);
        this.map.off("mouseenter", "pois", this.onPOIsMouseEnterDebounce);
        this.map.off("mouseleave", "pois", this.onPOIsMouseLeave);
        this.map.off("click", "pois", this.onPOIsClick);
        super.onRemove();
    }

    private onPOIsMouseEnter = () => {
        this.map.getCanvas().style.cursor = "pointer";
    };

    private onPOIsMouseEnterDebounce = debounce((e: MapLayerMouseEvent) => {
        const { name, category_id, address } = e.features[ 0 ].properties;
        const geometry = e.features[ 0 ].geometry as GeoJSON.Point;
        const coordinates = (geometry.coordinates || e.lngLat.toArray()) as [ number, number ];

        const category = this.getPOICategory(category_id);

        while (Math.abs(e.lngLat.lng - coordinates[ 0 ]) > 180) {
            coordinates[ 0 ] += e.lngLat.lng > coordinates[ 0 ] ? 360 : -360;
        }

        const renderTitle = () => {
            if (name) return html`<p class="nmapsgl-title">${name}</p>`;
            return "";
        };

        const renderAddress = () => {
            if (address) return html`<p class="nmapsgl-address">${address}</p>`;
            return "";
        };

        const renderCategory = () => {
            if (category) return html`
                <div class="nmapsgl-categories">
                    <span>${category.parent.name}</span>
                    ${category.child ? html`
                        <div class="bullet"></div>
                        <span>${category.child.name}</span>
                    ` : html``}
                </div>
            `;
            return "";
        };

        this.clearPopupTimeout();

        if (this.popup) {
            this.popup.remove();
            delete this.popup;
        }

        const content = html`
            ${renderTitle()}
            ${renderCategory()}
            ${renderAddress()}
        `;

        const markerHeight = 20;
        const markerRadius = 10;
        const linearOffset = 25;
        const popupOffsets: Offset = {
            "center": [ 0, 0 ],
            "top": [ 0, 10 ],
            "top-left": [ 0, 0 ],
            "top-right": [ 0, 0 ],
            "bottom": [ 0, -30 ],
            "bottom-left": [ linearOffset, (markerHeight - markerRadius + linearOffset) * -1 ],
            "bottom-right": [ -linearOffset, (markerHeight - markerRadius + linearOffset) * -1 ],
            "left": [ markerRadius, (markerHeight - markerRadius) * -1 ],
            "right": [ -markerRadius, (markerHeight - markerRadius) * -1 ]
        };

        this.popup = new Popup({ closeButton: false, closeOnMove: false, closeOnClick: false, className: "nmapsgl-poi-popup", offset: popupOffsets })
            .setLngLat(coordinates)
            .setDOMContent(content)
            .addTo(this.map);

        this.popup.getElement().addEventListener("mouseenter", this.clearPopupTimeout);
        this.popup.getElement().addEventListener("mouseleave", this.createPopupTimeout);
        this.popup.getElement().addEventListener("click", () => {
            if (this.directionsControl) {
                this.directionsControl.getResult(coordinates.reverse(), [ "poi" ], true)
                    .then((hidePopup) => {
                        if (hidePopup && this.popup) {
                            this.popup.remove();
                            delete this.popup;
                        }
                    });
            }
        });

    }, 250);

    private clearPopupTimeout = () => {
        if (this.popupCloseTimeout) {
            clearTimeout(this.popupCloseTimeout);
            this.popupCloseTimeout = null;
        }
    };

    private createPopupTimeout = () => {
        this.popupCloseTimeout = setTimeout(() => {
            if (this.popup) {
                this.popup.remove();
                delete this.popup;
            }
        }, 250);
    };

    private onPOIsMouseLeave = () => {
        this.map.getCanvas().style.cursor = "";
        this.onPOIsMouseEnterDebounce.cancel();
        this.createPopupTimeout();
    };

    private onPOIsClick = (e) => {
        // Get coordinates
        const geometry = e.features[ 0 ].geometry as GeoJSON.Point;
        const coordinates = (geometry.coordinates || e.lngLat.toArray()) as [ number, number ];
        while (Math.abs(e.lngLat.lng - coordinates[ 0 ]) > 180) {
            coordinates[ 0 ] += e.lngLat.lng > coordinates[ 0 ] ? 360 : -360;
        }

        // Open panel
        if (this.directionsControl) {
            this.directionsControl.getResult(coordinates.reverse(), [ "poi" ], true)
                .then((hidePopup) => {
                    if (hidePopup && this.popup) {
                        this.popup.remove();
                        delete this.popup;
                    }
                });
        }
    };

    private getCategories() {
        return makeNMapsRequest({ url: `${getApiUrl()}/v1/places/categories/en`, type: "json" })
            .then(({ data }) => this.categories = data.categories);
    }

    private getPOICategory(id: string, categories?: any[]): any {
        const _categories = categories || this.categories;
        for (const c of _categories) {
            if (c.id === id) return { "parent": c, "child": null };
            if (c.children) {
                const child = this.getPOICategory(id, c.children);
                if (child) return { "parent": c, "child": child.parent };
            }
        }
        return undefined;
    }
}
