import type { Map, MapMouseEvent } from "maplibre-gl";
import type { DirectionControl } from "~/ui/control";

import { arrow, computePosition, flip, offset, shift } from "@floating-ui/dom";
import { Popup } from "maplibre-gl";
import { DOM } from "maplibre-gl/src/util/dom";
import { reverseGeocode, timeZoneInfo } from "~/api";
import { RulerControl } from "~/ui/control";
import { changeTimeZone, dateFormat, isDirectionsControl, isRulerControl } from "~/utils";
import { Marker } from "../marker";

export class RightClickHandler { // TODO: implements Handler
    private map: Map;
    private rulerControl: RulerControl;
    private DirectionsControl: DirectionControl;
    private pinMarker: Marker;
    private controller: AbortController;

    constructor(map: Map) {
        this.map = map;
    }

    createOptions(e: MapMouseEvent & object) {
        // Remove Previous elems
        this._removeOldElems();

        // Ruler Control
        if (!this.rulerControl) {
            const control = this.map._controls.find(isRulerControl);
            if (control) {
                this.rulerControl = control;
            } else {
                this.rulerControl = new RulerControl({ button: false });
                this.map.addControl(this.rulerControl);
            }
        }

        // Create new element
        const elem = DOM.create("div", "nmapsgl-right-click", this.map.getCanvasContainer());
        const arrowEl = DOM.create("div", "nmapsgl-right-click-arrow", elem);
        const ulElem = DOM.create("ul", "nmapsgl-options-list", elem);

        // Check if DirectionControl exists
        const DirectionsControl = this.map._controls.find(isDirectionsControl);
        if (DirectionsControl) {
            this.DirectionsControl = DirectionsControl;
            // Add directions from
            const hasOrigin = DirectionsControl.hasOrigin();
            if (!hasOrigin && hasOrigin !== null) {
                this._addPointElem(ulElem, e, "origin", "Directions from here");
            }
            // Add directions to
            const hasDestination = DirectionsControl.hasDestination();
            if (!hasDestination && hasDestination !== null) {
                this._addPointElem(ulElem, e, "destination", "Directions to here");
            }
            // If click inside a layer
            if (this.map.getLayer("routing_layer") && this.map.getLayer("waypoints_layer")) {
                const features = this.map.queryRenderedFeatures(e.point, {
                    layers: [ "routing_layer", "waypoints_layer" ]
                });
                if (features.length > 0 && DirectionsControl.canRemoveWaypoint()) {
                    this._addPointElem(ulElem, e, "remove", "Remove waypoint");
                } else {
                    // Add waypoint
                    if (hasOrigin && hasDestination && !DirectionsControl.maxWaypoints()) {
                        this._addPointElem(ulElem, e, "waypoint", "Add waypoint");
                    }
                }
            }
        }

        // Drop pin
        const dropPin = DOM.create("li", "nmapsgl-options-list-elem", ulElem);
        const dropPinAnchor = DOM.create("div", "nmapsgl-options-list-content", dropPin);
        dropPinAnchor.textContent = "Drop pin";

        dropPinAnchor.addEventListener("click", () => {
            // Check if is not measuring
            if (this.rulerControl.isMeasuring) this.rulerControl._trigger();

            // Remove previous mark if exists
            this.pinMarker?.remove();

            // Normalize coordinates
            // eslint-disable-next-line prefer-const
            let { lat, lng } = e.lngLat;
            if (lng < -180) lng = lng + 180;
            else if (lng > 180) lng = lng - 180;
            const coordinates = { lng: +lng.toFixed(6), lat: +lat.toFixed(6) };

            if (this.controller) this.controller.abort();
            this.controller = new AbortController();

            const reversePromisse = reverseGeocode([ coordinates.lat, coordinates.lng ], this.controller)
                .then(({ data }) => {
                    if (data.results.length === 0) return;
                    return data.results[ 0 ];
                });

            const timeZonePromisse = timeZoneInfo(coordinates)
                .then(({ data }) => {
                    if (data.timezone_info.length === 0) return;
                    return data.timezone_info[ 0 ].timezone_id;
                });

            Promise.allSettled([ reversePromisse, timeZonePromisse ]).then(([ reverseRes, timezoneRes ]) => {
                const reverse = reverseRes.status === "fulfilled" && reverseRes.value;
                const timezone = timezoneRes.status === "fulfilled" && timezoneRes.value;

                // Add marker
                let roadElem;
                if (reverse) {
                    const title = reverse.name;

                    const regex = new RegExp(`\\b${title}\\b,? ?`);
                    const subtitle = reverse.formatted_address.replace(regex, "");

                    roadElem = `
                        <div class="drop-pin-wrapper">
                            <div>
                                ${title ? `<p class="nmapsgl-title">${title}</p>` : ""}
                                ${subtitle ? `<p class="nmapsgl-subtitle">${subtitle}</p>` : ""}
                            </div>
                            <button id="popup-routing-btn" class="nmapsgl-ctrl-directions-routing-btn"></button>
                        </div> 
                    `;
                }

                let timezoneElem = "";
                if (timezone) {
                    const date = dateFormat(changeTimeZone(new Date(), timezone));
                    timezoneElem += `
                        <div class="nmapsgl-popup-wrapper">
                            <div class="nmapsgl-popup-icon-drop nmapsgl-popup-timezone"></div>
                            ${date} (${timezone})
                        </div>
                    `;
                }

                const popup = new Popup({ closeButton: false, closeOnClick: false, className: "nmapsgl-pin-popup", offset: [ 0, -2 ] }).setHTML(`
                    ${roadElem}
                    <div class="nmapsgl-popup-wrapper">
                        <div class="nmapsgl-popup-icon-drop nmapsgl-popup-coordinates"></div>
                        ${coordinates.lat}, ${coordinates.lng}
                    </div>
                    ${timezoneElem}
                `);

                const el = DOM.create("div", "nmapsgl-drop-pin");
                this.pinMarker = new Marker({ element: el }).setLngLat(e.lngLat).setPopup(popup).addTo(this.map);
                this.pinMarker.togglePopup();

                // Click on popup content
                popup.getElement().addEventListener("click", () => {
                    this.pinMarker.remove();
                    const input = (document.getElementById("search-input") as HTMLInputElement);
                    this.DirectionsControl.showResult(input, reverse);
                });

                // Click on routing button
                window.document.getElementById("popup-routing-btn").addEventListener("click", (e) => {
                    e.stopPropagation();
                    this.pinMarker.remove();
                    this.DirectionsControl.linkToDirections(reverse);
                });
            });
        });

        // Measure distance
        if (!this.rulerControl.isMeasuring) {
            const measureDist = DOM.create("li", "nmapsgl-options-list-elem", ulElem);
            const measureDistAnchor = DOM.create("div", "nmapsgl-options-list-content", measureDist);
            measureDistAnchor.textContent = "Measure distance";
            measureDistAnchor.addEventListener("click", () => {
                if (this.pinMarker) this.pinMarker.remove();
                this.rulerControl._trigger();
                this.rulerControl._mapClickListener(e);
            });
        } else {
            // Clear measurement
            const clearMeasureDist = DOM.create("li", "nmapsgl-options-list-elem", ulElem);
            const clearMeasureDistAnchor = DOM.create("div", "nmapsgl-options-list-content", clearMeasureDist);
            clearMeasureDistAnchor.textContent = "Clear measurement";
            clearMeasureDistAnchor.addEventListener("click", () => {
                this.rulerControl._trigger();
            });
        }

        // Calculate position
        const virtualEl = {
            getBoundingClientRect: () => {
                return {
                    x: 0,
                    y: 0,
                    left: e.point.x,
                    top: e.point.y,
                    right: 0,
                    bottom: 0,
                    width: 1,
                    height: 1,
                };
            },
        };
        computePosition(virtualEl, elem, {
            placement: "top",
            middleware: [
                offset(arrowEl.offsetHeight / 2),
                flip({ padding: 8, crossAxis: false }),
                shift({ padding: 8 }),
                arrow({ element: arrowEl, padding: 8 })
            ]
        }).then(({ x, y, middlewareData, placement }) => {
            elem.style.setProperty("left", `${x}px`);
            elem.style.setProperty("top", `${y}px`);

            const side = placement.split("-")[ 0 ];
            const staticSide = {
                top: "bottom",
                right: "left",
                bottom: "top",
                left: "right"
            }[ side ];

            if (middlewareData.arrow) {
                const { x, y } = middlewareData.arrow;
                const arrowLen = arrowEl.offsetWidth;

                Object.assign(arrowEl.style, {
                    left: x != null ? `${x}px` : "",
                    top: y != null ? `${y}px` : "",
                    right: "",
                    bottom: "",
                    [ staticSide ]: `${-arrowLen / 2}px`,
                });
            }
        });

    }

    _click(e) {
        // Remove old selection
        this._removeOldElems();
        // Remove pin markers
        if (this.pinMarker && e.originalEvent.target.nodeName === "CANVAS" && (!this.rulerControl || this.rulerControl && !this.rulerControl.isMeasuring)) this.pinMarker.remove();
    }

    _removeOldElems() {
        // Remove old elements
        const oldElems = window.document.getElementsByClassName("nmapsgl-right-click");
        Array.from(oldElems).forEach(elem => elem.remove());
    }

    _addPointElem(ulElem: HTMLElement, e: MapMouseEvent, label, text) {
        const listElem = DOM.create("li", "nmapsgl-options-list-elem", ulElem);
        const listElemContent = DOM.create("div", "nmapsgl-options-list-content", listElem);
        listElemContent.textContent = text;
        listElemContent.addEventListener("click", () => {
            if (label == "remove") {
                this.DirectionsControl._removePoint(e.point);
            } else {
                this.DirectionsControl._addPoint(e.lngLat, label);
            }
        });
    }
}
