import type { StyleSpecification } from "maplibre-gl";
import type { Map } from "~/ui";

import { KeyboardHandler as _KeyboardHandler } from "maplibre-gl/src/ui/handler/keyboard";
import { getDefaultStyle } from "~/constants";
import { isGeolocateControl } from "~/utils";
import { TrafficLayer } from "../layers";

// @ts-ignore
export class KeyboardHandler extends _KeyboardHandler {
    private _previousStyle: StyleSpecification;

    // @ts-expect-error Override
    keydown(e: KeyboardEvent): { cameraAnimation: (map: Map) => void; } {
        switch (e.code) {
            case "Escape":
                // Escape: Close popups
                Array.from(window.document.getElementsByClassName("maplibregl-popup"))
                    .forEach(elem => elem.remove());
                break;

            case "KeyF":
                // Ctrl + Shift + F: Reset view and tilt
                if (e.shiftKey && e.ctrlKey) {
                    return {
                        cameraAnimation: (map: Map) => {
                            map.easeTo({
                                duration: 300,
                                bearing: 0,
                                pitch: 0
                            });
                        }
                    };
                }
                break;

            case "KeyI":
                // Ctrl + Shift + I: Show your location
                if (e.shiftKey && e.ctrlKey) {
                    return {
                        cameraAnimation: (map: Map) => {
                            const geolocateControl = map._controls.find(isGeolocateControl);
                            if (geolocateControl) geolocateControl.trigger();
                        }
                    };
                }
                break;

            case "KeyN":
                // Ctrl + Shift + N: Reset view
                if (e.shiftKey && e.ctrlKey) {
                    return {
                        cameraAnimation: (map: Map) => {
                            map.easeTo({ bearing: 0 });
                        }
                    };
                }
                break;

            case "KeyT":
                // Ctrl + Shift + T: Enable/Disable traffic
                if (e.shiftKey && e.ctrlKey) {
                    return {
                        cameraAnimation: (map: Map) => {
                            const instance = map.addLayerInstance(TrafficLayer);
                            instance.setState(!instance.isEnabled());
                        }
                    };
                }
                break;

            case "KeyU":
                // Ctrl + Shift + U: Reset Tilt
                if (e.shiftKey && e.ctrlKey) {
                    return {
                        cameraAnimation: (map: Map) => {
                            map.easeTo({ pitch: 0 });
                        }
                    };
                }
                break;

            case "KeyS":
                // Ctrl + Shift + S : Enable/Disable satellite
                if (e.shiftKey && e.ctrlKey) {
                    return {
                        cameraAnimation: (map: Map) => {
                            const currentStyle = map.getStyle();
                            const isSatellite = /satellite/i.test(currentStyle.name);
                            if (isSatellite && this._previousStyle) {
                                map.setStyle(this._previousStyle, { persistLayers: true });
                                delete this._previousStyle;
                            } else {
                                this._previousStyle = currentStyle;
                                map.setStyle(getDefaultStyle("SATELLITE_STYLE").styleUrl, { persistLayers: true });
                            }
                        }
                    };
                }
                break;

            default:
                // @ts-ignore
                return super.keydown(e);
        }
    }

}
