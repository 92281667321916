import type { LayerSpecification, Map, SourceSpecification } from "maplibre-gl";
import type { IShape } from "./shape";

import { LngLat } from "maplibre-gl";
import { extend } from "maplibre-gl/src/util/util";
import { uniqueId } from "~/utils";

type RectangleOptions = {
    strokeColor?: string,
    strokeOpacity?: number,
    strokeWeight?: number,
    fillColor?: string,
    fillOpacity: number
};

const defaultOptions: RectangleOptions = {
    strokeColor: "#000000",
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: "#000000",
    fillOpacity: 0.5
};

function validateOptions(options: RectangleOptions) {
    if (options.strokeOpacity < 0 || options.strokeOpacity > 1) throw Error("Invalid stroke opacity");
    if (options.strokeWeight < 0) throw Error("Invalid stroke weight");
    if (options.fillOpacity < 0 || options.fillOpacity > 1) throw Error("Invalid fill opacity");
    return true;
}

/**
 * Creates a rectangle component.
 * @param {Object} [options]
 * @param {string} [options.strokeColor] The color used for the rectangle outline. The default is black (#000000).
 * @param {number} [options.strokeOpacity] Specifies a numerical value between 0.0 and 1.0 to determine the opacity of the outline's color. The default is 1.0.
 * @param {number} [options.strokeWeight] The width of the outline in pixels. The default is 3.
 * @param {string} [options.fillColor] The color used to fill the rectangle area. The default is black (#000000).
 * @param {number} [options.fillOpacity]  Specifies a numerical value between 0.0 and 1.0 to determine the opacity of the rectangle's color area. The default is 0.5.
 * @example
 * const bounds = {
 *   north: 33.685,
 *   south: 33.671,
 *   east: -116.234,
 *   west: -116.251,
 * };
 *
 * let rectangle = new nmapsgl.Rectangle()
 *   .setBounds(bounds)
 *   .addTo(map);
 * @example
 * // Set options
 * let rectangle = new nmapsgl.Rectangle({
 *     strokeColor: "#FF0000",
 *     strokeOpacity: 0.8,
 *     strokeWeight: 2,
 *     fillColor: "#FF0000",
 *     fillOpacity: 0.35,
 *   }).setBounds(bounds)
 *   .addTo(map);
 */
export class Rectangle implements IShape {
    _map: Map;
    _rectangleID: string;
    _outlineID: string;
    _boundsLnglat: LngLat[];
    private _options: RectangleOptions;

    constructor(options: RectangleOptions) {
        this._options = extend({}, defaultOptions, options);
        validateOptions(this._options);
    }

    /**
     * Get the rectangle's geographical positions.
     *
     * @returns {LngLat[]} A {@link LngLat} array with the points that form the rectangle.
     * @example
     * // Store the rectangle points (longitude and latitude coordinates) in a variable
     * const points = rectangle.getPath();
     * // Print the point's longitude and latitude values in the console
     * points.forEach((item) => {
     *   console.log('Longitude: ' + item.lng + ', Latitude: ' + item.lat );
     * });
     */
    getBounds(): LngLat[] {
        return this._boundsLnglat;
    }

    /**
     * Set the rectangle's geographical positions.
     * @param {Object} bounds A object with the geographic boundaries of the rectangle.
     *  A rectangle is defined by four positions: north, south, east and west.
     * @returns {Rectangle} `this`
     * @example
     * // Create a new rectangle, set the points and add it to the map.
     * const bounds = {
     *   north: 33.685,
     *   south: 33.671,
     *   east: -116.234,
     *   west: -116.251,
     * };
     *
     * let rectangle = new nmapsgl.Rectangle()
     *   .setBounds(bounds)
     *   .addTo(map);
     */
    // FIXME: Add bounds type
    setBounds(bounds: any): this {
        const validBounds = [ "north", "south", "east", "west" ].every(e => !!bounds[ e ]);
        this._boundsLnglat = [];
        if (validBounds) {
            const topLeft = LngLat.convert([ bounds.west, bounds.north ]);
            const topRight = LngLat.convert([ bounds.east, bounds.north ]);
            const bottomLeft = LngLat.convert([ bounds.west, bounds.south ]);
            const bottomRight = LngLat.convert([ bounds.east, bounds.south ]);
            this._boundsLnglat.push(topLeft, topRight, bottomRight, bottomLeft, topLeft);
        } else {
            throw new Error("Bounds object is invalid. Please check documentation.");
        }
        return this;
    }

    /**
     * Attaches the `Rectangle` to a `Map` object.
     * @param {Map} map The NMaps GL JS map to add the rectangle to.
     * @returns {Rectangle} `this`
     * @example
     * const bounds = {
     *   north: 33.685,
     *   south: 33.671,
     *   east: -116.234,
     *   west: -116.251,
     * };
     *
     * let rectangle = new nmapsgl.Rectangle({
     *     strokeColor: "#FF0000",
     *     strokeOpacity: 0.8,
     *     strokeWeight: 2,
     *     fillColor: "#FF0000",
     *     fillOpacity: 0.35,
     *   }).setBounds(bounds)
     *   .addTo(map);
     */
    addTo(map: Map): this {
        this.remove();
        this._map = map;
        // Create polygon and outline ID
        this._rectangleID = uniqueId("rectangle");
        this._outlineID = uniqueId("rectangle-line");

        // Generate Source and Layer to polygon
        this._map.on("styledata", this._addSource);

        return this;
    }

    _addSource = () => {
        const hasSource = !!this._map.getSource(this._rectangleID);
        if (hasSource) return;

        const rectangleSource: SourceSpecification = {
            "type": "geojson",
            "data": {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [ this._boundsLnglat.map(e => e.toArray()) ]
                },
                "properties": {}
            }
        };
        const layer: LayerSpecification = {
            "id": this._rectangleID,
            "type": "fill",
            "source": this._rectangleID,
            "layout": {},
            "paint": {
                "fill-color": this._options.fillColor,
                "fill-opacity": this._options.fillOpacity
            }
        };

        const outline: LayerSpecification = {
            "id": this._outlineID,
            "type": "line",
            "source": this._rectangleID,
            "layout": {},
            "paint": {
                "line-color": this._options.strokeColor,
                "line-width": this._options.strokeWeight,
                "line-opacity": this._options.strokeOpacity
            }
        };

        this._map.addSource(this._rectangleID, rectangleSource);
        this._map.addLayer(layer);
        this._map.addLayer(outline);
    };

    /**
     * Removes the rectangle from a map.
     * @example
     * const bounds = {
     *   north: 33.685,
     *   south: 33.671,
     *   east: -116.234,
     *   west: -116.251,
     * };
     *
     * let rectangle = new nmapsgl.Rectangle()
     *   .setBounds(bounds)
     *   .addTo(map);
     *
     * rectangle.remove();
     * @returns {Rectangle} `this`
     */
    remove(): this {
        if (this._map) {
            if (this._map.getLayer(this._rectangleID)) this._map.removeLayer(this._rectangleID);
            if (this._map.getLayer(this._outlineID)) this._map.removeLayer(this._outlineID);
            this._map.removeSource(this._rectangleID);

            this._map.off("styledata", this._addSource);

            delete this._map;
        }
        return this;
    }
}
