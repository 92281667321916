import type { GeoJSONSource, IControl } from "maplibre-gl";
import type { Unit } from "~/types";
import type { Map } from "~/ui";

import { area, booleanPointInPolygon, buffer, distance, lineString, nearestPointOnLine, point, polygon } from "@turf/turf";
import { LngLat, Marker, Popup } from "maplibre-gl";
import { DOM } from "maplibre-gl/src/util/dom";
import { extend } from "maplibre-gl/src/util/util";
import { addTooltip, html } from "~/utils";

type RulerOptions = {
    unit?: Unit,
    button?: boolean
};

const defaultOptions: RulerOptions = {
    unit: "Metric",
    button: true
};

/**
 * A `RulerControl` provides a button to add points to the map and calculate the distance between them.
 *
 * @title Ruler
 * @implements {IControl}
 * @param {Object} [options]
 * @param {string} [options.unit=Metric] Distance unit: Metric or Imperial.
 *
 * @example
 * map.addControl(new nmapsgl.RulerControl({
 *      unit: 'Imperial'
 * }));
 */
export class RulerControl implements IControl {
    _map: Map;
    options: RulerOptions;
    _container: HTMLElement;
    _rulerButton: HTMLButtonElement;
    popover: HTMLElement;
    isMeasuring: boolean;
    markers: Marker[];
    coordinates: LngLat[];
    labels: string[];
    unit: string;
    isDragging = false;
    draggedMarker: Marker;
    draggedIndex = -1;

    constructor(options: RulerOptions) {
        this.options = extend({}, defaultOptions, options);
    }

    onAdd(map: Map) {
        this._map = map;
        this.renderPopover();
        this._container = DOM.create("div", "maplibregl-ctrl maplibregl-ctrl-group nmapsgl-ctrl-ruler");
        if (this.options.button) {
            this._rulerButton = DOM.create("button", "nmapsgl-ctrl-ruler", this._container);
            this._rulerButton.setAttribute("aria-label", "Ruler");
            DOM.create("span", "maplibregl-ctrl-icon", this._rulerButton);
            this._rulerButton.type = "button";
            this._rulerButton.addEventListener("click", this._trigger);
            addTooltip(this._rulerButton);
        }
        return this._container;
    }

    _trigger = () => {
        if (this.isMeasuring) {
            this.isMeasuring = false;
            if (this.options.button) {
                this._rulerButton.classList.remove("nmapsgl-ctrl-ruler-active");
            }
            // remove layers, sources and event listeners
            this._map.removeLayer("controls-layer-line");
            this._map.removeLayer("controls-layer-symbol");
            this._map.removeSource("controls-source-line");
            this._map.removeSource("controls-source-symbol");
            this.markers.forEach(m => m.remove());
            this._map.off("click", this._mapClickListener);
            this._map.off("styledata", this.draw);

            this._map._canvasContainer.style.cursor = "default";
            this.popover.style.display = "none";
        } else {
            this.isMeasuring = true;
            this.markers = [];
            this.coordinates = [];
            this.labels = [];
            if (this.options.button) {
                this._rulerButton.classList.add("nmapsgl-ctrl-ruler-active");
            }
            this.draw();
            this._map.on("click", this._mapClickListener);
            this._map.on("styledata", this.draw);

            this._map._canvasContainer.style.cursor = "pointer";
            this.popover.style.display = "block";
        }
    };

    draw = () => {
        const isDark = this._map.style.stylesheet.name == "dark";
        const color = isDark ? "#ffffff" : "#263238";
        const haloColor = isDark ? "#000000" : "#ffffff";

        if (!this._map.getSource("controls-source-line")) {
            this._map.addSource("controls-source-line", {
                type: "geojson",
                data: this.lineStringFeature(this.coordinates),
            });
        }

        if (!this._map.getSource("controls-source-symbol")) {
            this._map.addSource("controls-source-symbol", {
                type: "geojson",
                data: this.pointFeatureCollection(this.coordinates, this.labels),
            });
        }

        if (!this._map.getLayer("controls-layer-line")) {
            this._map.addLayer({
                id: "controls-layer-line",
                type: "line",
                source: "controls-source-line",
                paint: { "line-color": color, "line-width": 2 }
            });
        }

        if (!this._map.getLayer("controls-layer-symbol")) {
            this._map.addLayer({
                id: "controls-layer-symbol",
                type: "symbol",
                source: "controls-source-symbol",
                layout: { "text-field": "{text}", "text-anchor": "top", "text-size": 12, "text-offset": [ 0, 0.8 ] },
                paint: { "text-color": color, "text-halo-color": haloColor, "text-halo-width": 1 }
            });
        }

        this.markers.map(m => isDark ? m.addClassName("dark") : m.removeClassName("dark"));

        // Create temporary marker and popup to display on polyline hover
        const markerNode = DOM.create("div", `nmapsgl-ruler-marker ${isDark ? "dark" : ""}`);
        const tempMarker = new Marker({
            element: markerNode,
            draggable: false
        }).setLngLat([ 0, 0 ]).addTo(this._map);

        const tempPopup = new Popup({
            closeButton: false,
            className: "ruler-marker-popup"
        }).setHTML("Drag to change");

        this._map.on("mousemove", (e) => {
            if (this._map.style.hasLayer("controls-layer-line")) {
                const features = this._map.queryRenderedFeatures(e.point, { layers: [ "controls-layer-line" ] });
                if (features.length > 0) {
                    const _buffer = buffer(features[ 0 ].geometry, 0.05, { units: "kilometers" });
                    const isInsideBuffer = booleanPointInPolygon(point([ e.lngLat.lng, e.lngLat.lat ]), _buffer);
                    if (isInsideBuffer) {
                        tempMarker.setLngLat(e.lngLat).addTo(this._map);
                        tempMarker.setPopup(tempPopup);
                        tempMarker.togglePopup();
                    } else {
                        tempMarker.remove();
                    }
                } else {
                    tempMarker.remove();
                }
            }
        });

        // Add handler to support change polyline on drag
        this._map.on("mousedown", "controls-layer-line", (e) => {
            const clickedPoint = [ e.lngLat.lng, e.lngLat.lat ];
            this.isDragging = true;
            e.preventDefault();

            if (this.coordinates.length > 0) {
                const _lineString = lineString(this.coordinates.map(p => [ p.lng, p.lat ]));
                const snappedPoint = nearestPointOnLine(_lineString, clickedPoint);
                this.draggedIndex = snappedPoint.properties.index + 1;
            }
        });

        this._map.on("mousemove", (e) => {
            if (this.isDragging) {
                if (!this.draggedMarker) {
                    this.draggedMarker = this._setupMarker([ e.lngLat.lng, e.lngLat.lat ], this.draggedIndex);
                } else {
                    this.draggedMarker.setLngLat(e.lngLat);
                }
                const lngLat = this.draggedMarker.getLngLat();
                this.coordinates[ this.draggedIndex ] = new LngLat(lngLat.lng, lngLat.lat);
                this.markers[ this.draggedIndex ] = this.draggedMarker;
                this._renderRulerOnMap();
            }
        });

        this._map.on("mouseup", () => {
            if (this.isDragging) {
                this.isDragging = false;
                this.draggedIndex = -1;
                if (this.draggedMarker) this.draggedMarker = null;
            }
        });

    };

    _mapClickListener = (event: any) => {
        const features = this._map.queryRenderedFeatures(event.point, { layers: [ "controls-layer-line" ] });
        if (event.originalEvent.target.nodeName === "CANVAS" || features.length > 0) {
            const lineSource = this._map.getSource("controls-source-line");
            const symbolSource = this._map.getSource("controls-source-symbol");
            if (lineSource.type === "geojson" && symbolSource.type === "geojson") {
                this._setupMarker([ event.lngLat.lng, event.lngLat.lat ]);
            }
        }
    };

    _setupMarker([ lng, lat ], index?) {
        const isDark = this._map.style.stylesheet.name == "satellite" || this._map.style.stylesheet.name == "dark";
        const markerNode = DOM.create("div", `nmapsgl-ruler-marker ${isDark ? "dark" : ""}`);
        const marker = new Marker({ element: markerNode, draggable: true })
            .setLngLat([ lng, lat ])
            .addTo(this._map);
        const newCoordinate = new LngLat(lng, lat);
        index ? this.coordinates.splice(index, 0, newCoordinate) : this.coordinates.push(newCoordinate);
        index ? this.markers.splice(index, 0, marker) : this.markers.push(marker);
        this._renderRulerOnMap();

        marker.getElement().addEventListener("click", () => {
            const index = this.markers.indexOf(marker);
            if (index !== 0) {
                this.markers.splice(index, 1);
                this.coordinates.splice(index, 1);
                marker.remove();
                this._renderRulerOnMap();
            } else {
                this._setupMarker(marker._lngLat.toArray());
                marker.getElement().style.cursor = "default";
                marker.togglePopup();
                this._renderRulerOnMap();
            }
        });

        marker.on("drag", () => {
            const index = this.markers.indexOf(marker);
            const lngLat = marker.getLngLat();
            this.coordinates[ index ] = new LngLat(lngLat.lng, lngLat.lat);
            this.markers[ index ] = marker;
            this._renderRulerOnMap();
        });

        const popup = new Popup({
            closeButton: false,
            className: "ruler-marker-popup"
        }).setHTML("Click to remove, drag to move");

        let isAddingMarker = true;

        marker.getElement().addEventListener("mouseenter", () => {
            if (isAddingMarker) return;
            marker.getElement().style.cursor = "grab";
            marker.setPopup(popup);
            marker.togglePopup();
        });

        marker.getElement().addEventListener("mouseleave", () => {
            if (isAddingMarker) return;
            marker.getElement().style.cursor = "default";
            marker.togglePopup();
        });

        setTimeout(() => isAddingMarker = false, 500);

        return marker;
    }

    _calculateArea() {
        const totalAreaContainer = window.document.getElementById("total-area");
        if (totalAreaContainer) {
            totalAreaContainer.innerHTML = "";
            if (this.markers.length < 4) return;
            const startMarker = this.markers[ 0 ]._lngLat;
            const endMarker = this.markers[ this.markers.length - 1 ]._lngLat;
            if (startMarker.lat == endMarker.lat && startMarker.lng == endMarker.lng) {
                const { unit } = this.options;
                const totalAreaContainer = window.document.getElementById("total-area");
                totalAreaContainer.innerHTML = "";
                const value = document.createElement("span");

                const coordinates = this.markers.map(m => [ m._lngLat.lng, m._lngLat.lat ]);
                if (coordinates.length > 2) coordinates.push(coordinates[ 0 ]);
                const _polygon = polygon([ coordinates ]);
                const _area = area(_polygon);
                const totalAreaLabel = unit === "Metric" ? `${_area.toFixed(2)} m2` : `${(_area * 10.764).toFixed(2)} ft2`;

                value.textContent = `Total area: ${totalAreaLabel}`;
                totalAreaContainer.appendChild(value);
            }
        }
    }

    _renderRulerOnMap() {
        const lineSource = this._map.getSource("controls-source-line");
        const symbolSource = this._map.getSource("controls-source-symbol");

        this._updateLabels();
        this._calculateArea();

        const lineString = this.lineStringFeature(this.coordinates);
        (lineSource as GeoJSONSource).setData(lineString);
        (symbolSource as GeoJSONSource).setData(this.pointFeatureCollection(this.coordinates, this.labels));
    }

    _updateLabels() {
        const { coordinates } = this;
        const { unit } = this.options;
        let sum = 0;
        this.labels = coordinates.map((coordinate, index) => {
            if (index === 0) {
                const startMarker = this.markers[ 0 ]._lngLat;
                const endMarker = this.markers[ this.markers.length - 1 ]._lngLat;
                if (this.markers.length >= 2 && startMarker.lat == endMarker.lat && startMarker.lng == endMarker.lng) {
                    return "";
                } else {
                    return unit === "Metric" ? "0 m" : "0 ft";
                }
            }
            sum += distance(coordinates[ index - 1 ].toArray(), coordinates[ index ].toArray(), { units: unit === "Metric" ? "kilometers" : "miles" });
            if (unit === "Metric") {
                return (sum < 1) ? `${(sum * 1000).toFixed()} m` : `${sum.toFixed(2)} km`;
            } else {
                return (sum < 1) ? `${(sum * 5280).toFixed()} ft` : `${sum.toFixed(2)} ml`;
            }
        });

        const distanceContainer = window.document.getElementById("distance");
        if (distanceContainer) {
            distanceContainer.innerHTML = "";
            const value = document.createElement("span");
            let distanceLabel = "";
            if (unit === "Metric") {
                distanceLabel = (sum < 1) ? `${(sum * 1000).toFixed()} m` : `${sum.toFixed(2)} km`;
            } else {
                distanceLabel = (sum < 1) ? `${(sum * 5280).toFixed()} ft` : `${sum.toFixed(2)} ml`;
            }
            value.textContent = `Total distance: ${distanceLabel}`;
            distanceContainer.appendChild(value);
        }
    }

    lineStringFeature(coordinates: LngLat[]): GeoJSON.GeoJSON {
        return {
            type: "Feature",
            properties: {},
            geometry: {
                type: "LineString",
                coordinates: coordinates.map(e => e.toArray()),
            },
        };
    }

    pointFeatureCollection(coordinates: LngLat[] = [], labels: string[] = []): GeoJSON.GeoJSON {
        return {
            type: "FeatureCollection",
            features: coordinates.map((c, i) => ({
                type: "Feature",
                properties: {
                    text: labels[ i ],
                },
                geometry: {
                    type: "Point",
                    coordinates: c.toArray(),
                },
            })),
        };
    }

    onRemove() {
        if (this.isMeasuring) this._trigger();

        DOM.remove(this._container);
        this._map.off("click", this._mapClickListener);
        this._map.off("styledata", this.draw);
        delete this._map;
    }

    private renderPopover() {
        this.popover = this._map.getPopoverContainer("bottom").appendChild(html`
        <div id="nmapsgl-ctrl-ruler-popover" class="nmapsgl-popover nmapsgl-popover-persistent" style="display: none;">
            <div class="header">
                <h3>Measure distance</h3>
                <button class="close" onclick="${this._trigger}">
                    <span class="icon"></span>
                </button>
            </div>
            <div class="content">
                <span>All points will add up to the total measurement.</span>
                <div id="distance" class="label">
                    <span>Total distance: 0 m</span>
                </div>
                <div id="total-area" class="label">
                </div>
            </div>
        </div>
    `);
    }
}
