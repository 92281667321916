
import { getJSON } from "maplibre-gl/src/util/ajax";
import { objectToUrlParams } from "~/utils";
import { getAccessToken, getApiUrl } from "..";

export type FlightState = {
    icao24: string,
    callsign?: string,
    origin_country: string,
    time_position?: number,
    last_contact: number,
    longitude?: number,
    latitude?: number,
    baro_altitude?: number,
    on_ground: boolean,
    velocity?: number,
    true_track?: number,
    vertical_rate?: number,
    sensors?: number[],
    geo_altitude?: number,
    squawk?: string,
    spi: boolean,
    position_source: 0 | 1 | 2 | 3,
    category: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20;
};

export type AllStatesResponse = {
    time: number,
    states: FlightState[]
};
export async function getAllStates(
    params: { time?: number, icao24?: string[], lamin?: number, lomin?: number, lamax?: number, lomax?: number },
    authorization: string,
    abortController: AbortController
): Promise<AllStatesResponse> {
    params[ "access_token" ] = getAccessToken();
    const queryParams = objectToUrlParams(params);
    const { data } = await getJSON<AllStatesResponse>({
        url: `${getApiUrl()}/opensky/states/all?${queryParams}`,
        headers: { Authorization: `Basic ${authorization}` }
    }, abortController);
    if (!Array.isArray(data.states)) {
        throw Error();
    }
    return {
        time: data.time,
        states: data.states.map(e => ({
            icao24: e[ 0 ],
            callsign: e[ 1 ]?.trim(),
            origin_country: e[ 2 ],
            time_position: e[ 3 ],
            last_contact: e[ 4 ],
            longitude: e[ 5 ],
            latitude: e[ 6 ],
            baro_altitude: e[ 7 ],
            on_ground: e[ 8 ],
            velocity: e[ 9 ],
            true_track: e[ 10 ],
            vertical_rate: e[ 11 ],
            sensors: e[ 12 ],
            geo_altitude: e[ 13 ],
            squawk: e[ 14 ],
            spi: e[ 15 ],
            position_source: e[ 16 ],
            category: e[ 17 ]
        }))
    };
}

export type TrackByAircraftResponse = {
    icao24: string,
    startTime: number,
    endTime: number,
    callsign: string,
    path: {
        time: number,
        latitude: number,
        longitude: number,
        baro_altitude: number,
        true_track: number,
        on_ground: boolean
    }[]
};
export async function getTrajectory(
    params: { icao24: string },
    authorization: string,
    abortController: AbortController
): Promise<TrackByAircraftResponse> {
    params[ "access_token" ] = getAccessToken();
    const queryParams = objectToUrlParams(params);
    const { data } = await getJSON<TrackByAircraftResponse>({
        url: `${getApiUrl()}/opensky/tracks/?${queryParams}`,
        headers: { Authorization: `Basic ${authorization}` }
    }, abortController);
    return {
        ...data,
        path: data.path.map(e => ({
            time: e[ 0 ],
            latitude: e[ 1 ],
            longitude: e[ 2 ],
            baro_altitude: e[ 3 ],
            true_track: e[ 4 ],
            on_ground: e[ 5 ]
        }))
    };
}

export type GetRouteResponse = {
    callsign: string;
    route: [ string, string ];
    updateTime: number;
    operatorIata: string;
    flightNumber: number;
};
export async function getRoute(params: { callsign: string }, authorization: string, abortController: AbortController) {
    params[ "access_token" ] = getAccessToken();
    const queryParams = objectToUrlParams(params);
    const response = await getJSON<GetRouteResponse>({
        url: `${getApiUrl()}/opensky/routes/?${queryParams}`,
        headers: { Authorization: `Basic ${authorization}` }
    }, abortController);
    return response.data;
}

export type GetAirportResponse = {
    icao: string;
    iata: string;
    name: string;
    city?: string;
    type?: string;
    position: {
        longitude: number;
        latitude: number;
        altitude: number;
        reasonable: boolean;
    };
    continent: string;
    country: string;
    region: string;
    municipality: string;
    gpsCode: string;
    homepage: string;
    wikipedia: string;
};
export async function getAirports(
    params: { lamin?: number, lomin?: number, lamax?: number, lomax?: number },
    authorization: string,
    abortController: AbortController
): Promise<GetAirportResponse[]> {
    params[ "access_token" ] = getAccessToken();
    const queryParams = objectToUrlParams(params);
    const { data } = await getJSON<GetAirportResponse[]>({
        url: `${getApiUrl()}/opensky/airports/region?${queryParams}`,
        headers: { Authorization: `Basic ${authorization}` }
    }, abortController);
    return data;
}

export async function getAirport(
    params: { icao: string }, authorization: string, abortController: AbortController
) {
    params[ "access_token" ] = getAccessToken();
    const queryParams = objectToUrlParams(params);
    const { data } = await getJSON<GetAirportResponse>({
        url: `${getApiUrl()}/opensky/airports/?${queryParams}`,
        headers: { Authorization: `Basic ${authorization}` }
    }, abortController);
    return data;
}
