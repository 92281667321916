import type { DirectionControl } from "~/index";
import type { Map } from "~/ui/map";
import type { LayerFilter, LayerInstanceOptions } from "./layer_instance";

import { extend } from "maplibre-gl/src/util/util";
import { isDirectionsControl } from "~/utils";
import { LayerInstance } from "./layer_instance";

const defaultOptions: LayerInstanceOptions = {
    initialEnabled: true
};

export class APTLayer extends LayerInstance {
    static readonly id = "nmapsgl_apt";
    static readonly title = "APT";

    private directionsControl: DirectionControl = null;

    constructor(options?: LayerInstanceOptions) {
        options = extend({}, defaultOptions, options);
        const filters: LayerFilter[] = [
            {
                id: "apt",
                filter: l => l.id === "apt",
            }
        ];
        super(filters, options);
    }

    onAdd(map: Map): void {
        super.onAdd(map);
        this.map.on("mouseenter", "apt", this.onAPTMouseEnter);
        this.map.on("mouseleave", "apt", this.onAPTMouseLeave);
        this.map.on("click", "apt", this.onAPTClick);
        const control = this.map._controls.find(isDirectionsControl);
        if (control) this.directionsControl = control;
    }

    onRemove(): void {
        this.map.off("mouseenter", "apt", this.onAPTMouseEnter);
        this.map.off("mouseleave", "apt", this.onAPTMouseLeave);
        this.map.off("click", "apt", this.onAPTClick);
        super.onRemove();
    }

    private onAPTMouseEnter = () => {
        this.map.getCanvas().style.cursor = "pointer";
    };

    private onAPTMouseLeave = () => {
        this.map.getCanvas().style.cursor = "";
    };

    private onAPTClick = (e) => {
        // Get coordinates
        const geometry = e.features[ 0 ].geometry as GeoJSON.Point;
        const coordinates = (geometry.coordinates || e.lngLat.toArray()) as [ number, number ];
        while (Math.abs(e.lngLat.lng - coordinates[ 0 ]) > 180) {
            coordinates[ 0 ] += e.lngLat.lng > coordinates[ 0 ] ? 360 : -360;
        }

        // Open panel
        if (this.directionsControl) {
            this.directionsControl.getResult(coordinates.reverse(), [ "house_number" ], true);
        }
    };
}
