import type { GetResourceResponse, IControl, Map } from "maplibre-gl";

import debounce from "lodash.debounce";
import { DOM } from "maplibre-gl/src/util/dom";
import { extend } from "maplibre-gl/src/util/util";
import Typeahead from "suggestions";
import { genericSearch, reverseGeocode } from "~/api";
import { geocoderExcludedkeys } from "~/constants";
import { Marker } from "../marker";

export type GeocoderOptions = {
    zoom?: number,
    flyTo?: boolean,
    limit?: number,
    minLength?: number,
    getItemValue?: (item: any) => string,
    render?: (item: any) => string,
    placeholder: string
};

const defaultOptions: GeocoderOptions = {
    zoom: 16,
    flyTo: true,
    limit: 5,
    minLength: 2,
    placeholder: "Search",
    getItemValue: (item) => {
        switch (item.type) {
            case "country":
                return item.name;
            case "poi":
                return `${item.name}, ${item.formatted_address.replace("\n", ", ")}`;
            default:
                return item.formatted_address.replace("\n", ", ");
        }
    },
    render: (item) => {
        const distance = item.distance > 100 ? `${(item.distance / 1000).toFixed(2)} km` : `${item.distance} m`;
        const regex = new RegExp(`${item.name},?`);
        const filterAddress = item.formatted_address.replace(regex, "");
        switch (item.type) {
            case "country":
                return `<div class="nmapsgl-ctrl-geocoder-wrapper"><div class="nmapsgl-ctrl-geocoder-icon nmapsgl-ctrl-geocoder-${item.type}"></div><div class="nmapsgl-ctrl-geocoder--suggestion"><div><div class="nmapsgl-ctrl-geocoder--suggestion-title">${item.name}</div><div class="nmapsgl-ctrl-geocoder--suggestion-address"><span>${distance}</span></div></div></div>`;
            default:
                return `<div class="nmapsgl-ctrl-geocoder-wrapper"><div class="nmapsgl-ctrl-geocoder-icon nmapsgl-ctrl-geocoder-${item.type}"></div><div class="nmapsgl-ctrl-geocoder--suggestion"><div class="nmapsgl-ctrl-geocoder--suggestion-title">${item.name}</div><div class="nmapsgl-ctrl-geocoder--suggestion-address"><span>${distance}</span><span class="nmapsgl-dot"></span><span style="max-width: 215px">${filterAddress}</span></div></div></div>`;
        }
    }
};

/**
 * A `GeocoderControl` allows the user to search for places and Points of Interest (POIs). It's possible to do generic searches as well as reverse geocoding using this control.
 *
 * @implements {IControl}
 * @param {Object} [options]
 * @param {number} [options.zoom=16] Defines zoom level after selecting the search result.
 * @param {boolean} [options.flyTo=true] Adds the fly effect to the selected search.
 * @param {number} [options.limit=5] Number of results to be presented in the search.
 * @example
 * const geocoder = new nmapsgl.GeocoderControl({
 *     zoom: 14,
 *     flyTo: false,
 *     limit: 3
 * });
 * map.addControl(geocoder);
 */
export class GeocoderControl implements IControl {
    _map: Map;
    options: GeocoderOptions;
    _container: HTMLElement;
    _clearEl: HTMLElement;
    lastSelected: string;
    controller: AbortController;
    _inputEl: HTMLInputElement;
    _loadingEl: SVGSVGElement;
    _typeahead: typeof Typeahead;
    mapMarker: Marker;

    constructor(options: GeocoderOptions) {
        this.options = extend({}, defaultOptions, options);
    }

    onAdd(map: Map): HTMLElement {
        this._map = map;

        this._container = DOM.create("div", "nmapsgl-ctrl-geocoder maplibregl-ctrl nmapsgl-ctrl-geocoder-control");
        this._container.addEventListener("mouseenter", this._showButton);
        this._container.addEventListener("mouseleave", this._hideButton);

        this._inputEl = DOM.create("input", "nmapsgl-ctrl-geocoder-input", this._container);
        this._inputEl.type = "text";
        this._inputEl.placeholder = this.options.placeholder;
        this._inputEl.setAttribute("aria-label", this.options.placeholder);
        this._inputEl.addEventListener("keydown", debounce(this._keydown, 200));
        this._inputEl.addEventListener("change", this._onChange);

        const buttonIcon = this.createIcon("close", "<path d=\"M3.8 2.5c-.6 0-1.3.7-1.3 1.3 0 .3.2.7.5.8L7.2 9 3 13.2c-.3.3-.5.7-.5 1 0 .6.7 1.3 1.3 1.3.3 0 .7-.2 1-.5L9 10.8l4.2 4.2c.2.3.7.3 1 .3.6 0 1.3-.7 1.3-1.3 0-.3-.2-.7-.3-1l-4.4-4L15 4.6c.3-.2.5-.5.5-.8 0-.7-.7-1.3-1.3-1.3-.3 0-.7.2-1 .3L9 7.1 4.8 2.8c-.3-.1-.7-.3-1-.3z\"/>");
        this._clearEl = DOM.create("button", "nmapsgl-ctrl-geocoder--button");
        this._clearEl.setAttribute("aria-label", "Clear");
        this._clearEl.addEventListener("click", this.clear);
        this._clearEl.appendChild(buttonIcon);

        this._loadingEl = this.createIcon("loading", "<path fill=\"#333\" d=\"M4.4 4.4l.8.8c2.1-2.1 5.5-2.1 7.6 0l.8-.8c-2.5-2.5-6.7-2.5-9.2 0z\"/><path opacity=\".1\" d=\"M12.8 12.9c-2.1 2.1-5.5 2.1-7.6 0-2.1-2.1-2.1-5.5 0-7.7l-.8-.8c-2.5 2.5-2.5 6.7 0 9.2s6.6 2.5 9.2 0 2.5-6.6 0-9.2l-.8.8c2.2 2.1 2.2 5.6 0 7.7z\"/>");
        const actions = DOM.create("div", "nmapsgl-ctrl-geocoder--pin-right");
        actions.appendChild(this._clearEl);
        actions.appendChild(this._loadingEl);

        const searchIcon = this.createIcon("search", "<path d=\"M7.4 2.5c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9c1 0 1.8-.2 2.5-.8l3.7 3.7c.2.2.4.3.8.3.7 0 1.1-.4 1.1-1.1 0-.3-.1-.5-.3-.8L11.4 10c.4-.8.8-1.6.8-2.5.1-2.8-2.1-5-4.8-5zm0 1.6c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2-3.3-1.3-3.3-3.1 1.4-3.3 3.3-3.3z\"/>");
        this._container.appendChild(searchIcon);
        this._container.appendChild(this._inputEl);
        this._container.appendChild(actions);

        this._typeahead = new Typeahead(this._inputEl, [], {
            filter: false,
            minLength: this.options.minLength,
            limit: this.options.limit
        });

        if (typeof this.options.render === "function") this._typeahead.render = this.options.render;

        this._typeahead.getItemValue = this.options.getItemValue;

        return this._container;
    }

    createIcon(name, path) {
        const icon: SVGSVGElement = window.document.createElementNS("http://www.w3.org/2000/svg", "svg");
        icon.setAttribute("class", `nmapsgl-ctrl-geocoder--icon nmapsgl-ctrl-geocoder--icon-${name}`);
        icon.setAttribute("viewBox", "0 0 18 18");
        icon.setAttribute("xml:space", "preserve");
        icon.setAttribute("width", "18px");
        icon.setAttribute("height", "18px");
        icon.innerHTML = path;
        return icon;
    }

    _showButton = () => {
        if (this._typeahead.selected) this._clearEl.style.display = "block";
    };

    _hideButton = () => {
        if (this._typeahead.selected) this._clearEl.style.display = "none";
    };

    _keydown = (e) => {
        if (e.key === "Enter") {
            if (!this._typeahead.selected) {
                const searchInput = e.target.value;
                if (searchInput) {
                    this._clearEl.style.display = "none";
                    this._loadingEl.style.display = "block";

                    if (this.controller) this.controller.abort();
                    this.controller = new AbortController();

                    // Initialize request
                    let request: Promise<GetResourceResponse<any>>;

                    if (/(-?\d+\.?\d*)[, ]+(-?\d+\.?\d*)[ ]*$/.test(searchInput)) {
                        // parse coordinates
                        const coords = searchInput.split(/[\s(,)?]+/).map((c) => {
                            return parseFloat(c);
                        });
                        request = reverseGeocode(coords, this.controller, this.options.limit);
                    } else {
                        const center = this._map.getCenter();
                        request = genericSearch(searchInput, center, this.controller, this.options.limit);
                    }

                    request
                        .then(({ data }) => {
                            this._loadingEl.style.display = "none";
                            this.controller = null;
                            if (data.results.length) {
                                this._clearEl.style.display = "block";
                                this._typeahead.update(data.results);
                            } else {
                                this._clearEl.style.display = "none";
                                this._typeahead.selected = null;
                                this._renderMessage("<div class='nmapsgl-geocoder-error nmaps-gl-geocoder--no-results'>No results found</div>");
                            }
                        })
                        .catch((e) => {
                            if (e.name !== "AbortError") {
                                this.controller = null;
                                this._loadingEl.style.display = "none";
                                this._clearEl.style.display = "none";
                                this._typeahead.selected = null;
                                this._renderMessage("<div class='nmapsgl-geocoder-error'>There was an error reaching the server</div>");
                            }
                        });
                }
            }
        } else if (!geocoderExcludedkeys.includes(e.key)) {
            this._typeahead.clear();
            this._typeahead.selected = null;
        }
    };

    _onChange = () => {
        const selected = this._typeahead.selected;
        if (selected && JSON.stringify(selected) !== this.lastSelected) {
            this._clearEl.style.display = "none";
            if (this.options.flyTo) {
                this._map.flyTo({
                    center: selected.location,
                    speed: 1.3,
                    zoom: this.options.zoom
                });
            } else {
                this._map.jumpTo({
                    center: selected.location,
                    zoom: this.options.zoom
                });
            }
            this._handleMarker(selected);

            // After selecting a feature, re-focus the textarea and set
            // cursor at start.
            this._inputEl.focus();
            this._inputEl.scrollLeft = 0;
            this._inputEl.setSelectionRange(0, 0);
            this.lastSelected = JSON.stringify(selected);
            this._typeahead.clear();
        }
    };

    clear = (ev) => {
        if (ev) ev.preventDefault();
        this._inputEl.value = "";
        this._typeahead.selected = null;
        this._typeahead.clear();
        this._onChange();
        this._clearEl.style.display = "none";
        this._removeMarker();
        this.lastSelected = null;
        this._inputEl.focus();
    };

    onRemove(): void {
        DOM.remove(this._container);
        this._removeMarker();
        delete this._map;
    }

    _removeMarker() {
        if (this.mapMarker) {
            this.mapMarker.remove();
            this.mapMarker = null;
        }
    }

    _renderMessage(msg) {
        this._typeahead.update([]);
        this._typeahead.selected = null;
        this._typeahead.clear();
        this._typeahead.renderError(msg);
    }

    _handleMarker(selected) {
        // clean up any old marker that might be present
        if (!this._map) return;

        this._removeMarker();
        if (selected.location) {
            this.mapMarker = new Marker()
                .setLngLat(selected.location)
                .addTo(this._map);
        }
        return this;
    }

}
