import type { LngLat } from "mapillary-js";
import type { GeoJSONSource, IControl, LayerSpecification, Map, SourceSpecification } from "maplibre-gl";
import type { Unit } from "~/types";

import polyline from "@mapbox/polyline";
import debounce from "lodash.debounce";
import flatten from "lodash.flatten";
import { LngLatBounds, Popup } from "maplibre-gl";
import { DOM } from "maplibre-gl/src/util/dom";
import { extend } from "maplibre-gl/src/util/util";
import { genericSearch, getDirections, reverseGeocode, roadsInfo } from "~/api";
import { directionsSourceName, geocoderExcludedkeys } from "~/constants";
import { Marker } from "..";
import maneuvers from "./maneuvers.json";

type Profile = "Car" | "Truck" | "Walking";
type RoutingOptions = "Motorways" | "Tolls" | "Ferries" | "Freeways" | "Uturns" | "Small Roads" | "Country Crossing" | "Unpaved" | "Tunnels";

type DirectionOptions = {
    zoom?: number,
    flyTo?: boolean,
    traffic?: boolean,
    units?: Unit,
    limit?: number,
    profiles: Record<Profile, object>,
    profileIds?: Profile[],
    routingOptions?: RoutingOptions[],
};

const defaultOptions: DirectionOptions = {
    zoom: 16,
    flyTo: true,
    traffic: false,
    units: "Metric",
    limit: 5,
    profileIds: [ "Car", "Truck", "Walking" ],
    profiles: {
        "Car": {
            "mode": "fastest",
            "transportation": "Car"
        },
        "Truck": {
            "mode": "fastest",
            "transportation": "Truck"
        },
        "Walking": {
            "mode": "pedestrian",
            "transportation": "Car"
        }
    },
    routingOptions: [ "Motorways", "Tolls", "Ferries" ]
};

const options = [
    { "name": "Motorways", "value": "motorways" },
    { "name": "Tolls", "value": "toll" },
    { "name": "Ferries", "value": "ferry" },
    { "name": "Freeways", "value": "freeways" },
    { "name": "Uturns", "value": "uturns" },
    { "name": "Small Roads", "value": "small_roads" },
    { "name": "Country Crossing", "value": "country_crossing" },
    { "name": "Unpaved", "value": "unpaved" },
    { "name": "Tunnels", "value": "tunnels" }
];

/**
 * A `DirectionControl` allows the user to search for a route between 2 points: origin and destination (which can be reversed). The search point can be a c search (streets, cities, points of interest, etc) as well as a reverse geocode. The route profiles to be used can be defined in this control, which can be customized by the user with route options (avoids and units).
 *
 * @implements {IControl}
 * @param {Object} [options]
 * @param {number} [options.zoom=16] Defines zoom level after selecting the search result.
 * @param {boolean} [options.flyTo=true] Adds the fly effect to the selected search.
 * @param {boolean} [options.traffic=false] Consider traffic on route calculation.
 * @param {number} [options.limit=5] Number of results to be presented in the search.
 * @param {string} [options.units='Metric'] Unit of the distance (`'Metric'` or `'Imperial'`).
 * @param {Array<string>} [options.profileIds=['Car', 'Truck', 'Walking']] Available profiles for routing: `'Car'`, `'Truck'`, `'Walking'`.
 * @param {Array<string>} [options.routingOptions=['Motorways', 'Tolls', 'Ferries', 'Freeways', 'Uturns', 'Small Roads', 'Country Crossing', 'Unpaved', 'Tunnels']] Available options for routing: `'Motorways'`, `'Tolls'`, `'Ferries'`, `'Freeways'`, `'Uturns'`, `'Small Roads'`, `'Country Crossing'`, `'Unpaved'`, `'Tunnels'`.
 * @example
 * const routing = new nmapsgl.RoutingControl({
 *     zoom: 14,
 *     flyTo: false,
 *     traffic: true,
 *     limit: 3,
 *     profileIds: ['Car', 'Walking'],
 *     routingOptions: ['Motorways', 'Tolls', 'Ferries']
 * });
 * map.addControl(routing);
 */
export class RoutingControl implements IControl {
    _map: Map;
    options: DirectionOptions;
    _container: HTMLElement;
    _instructions: HTMLElement;
    _geojson: GeoJSON.FeatureCollection;
    _layers: LayerSpecification[] = [];
    _selectedAlternative: string;
    _routing = {};
    _waypointNumber = 0;
    controller: AbortController;
    _reverseButton: HTMLButtonElement;
    _suggestions: HTMLDivElement;
    _maneuverMarker: Marker;
    activeProfile?: Profile;
    _popup: Popup;

    constructor(options: DirectionOptions) {
        this.options = extend({}, defaultOptions, options);
    }

    onAdd(map: Map): HTMLElement {
        this._map = map;

        this._container = DOM.create("div", "nmapsgl-ctrl-routing maplibregl-ctrl");

        // Profiles
        const profilesEl = this._createProfilesElement();
        this._container.appendChild(profilesEl);

        // Add controls to the page
        const inputEl = DOM.create("div", "nmapsgl-routing-control nmapsgl-routing-control-inputs");

        const inputWrapper = DOM.create("div", "nmapsgl-routing-input-wrapper");
        const waypoints = [ "origin", "destination" ];
        waypoints.forEach((w) => {
            const input = this._createInputElement(w);
            inputWrapper.appendChild(input);
        });

        this._reverseButton = DOM.create("button", "nmapsgl-routing-icon-reverse nmapsgl-routing-reverse nmapsgl-geocoder-icon js-reverse-inputs");
        this._reverseButton.addEventListener("click", this._reverse);

        inputEl.appendChild(inputWrapper);
        inputEl.appendChild(this._reverseButton);
        this._container.appendChild(inputEl);

        // Suggestions
        this._suggestions = DOM.create("div", "nmapsgl-routing-inputs-suggestions");
        this._container.appendChild(this._suggestions);

        // Options
        const optionsEl = this._createOptionsElement();
        this._container.appendChild(optionsEl);

        // Add Source
        this._map.on("styledata", () => {
            this.loadSource();
            const lineColor = getCustomColor(this._map.getStyle().name);
            // Update colors according to style
            this._layers.forEach((l) => {
                l.paint[ "line-color" ] = l.id === `routing-${this._selectedAlternative}` ? "#4ca0fe" : lineColor;
                if (!this._map.getLayer(l.id)) {
                    this._map.addLayer(l);
                }
            });
            if (this._selectedAlternative && this._map.getLayer(`routing-${this._selectedAlternative}`)) {
                this._map.moveLayer(`routing-${this._selectedAlternative}`);
            }
        });

        return this._container;
    }

    onRemove(): void {
        DOM.remove(this._container);
        delete this._map;
    }

    loadSource() {
        const geojson: SourceSpecification = {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: []
            }
        };

        // Add and set data theme layer/style
        if (!this._map.getSource(directionsSourceName)) {
            this._map.addSource(directionsSourceName, geojson);
            if (this._geojson) {
                (this._map.getSource(directionsSourceName) as GeoJSONSource).setData(this._geojson);
            }
        }
    }

    _createInputElement(direction) {
        const placeholder = direction === "origin" ? "Choose a starting place" : "Choose destination";

        // Template
        const el = DOM.create("div", `nmapsgl-ctrl-geocoder nmapsgl-ctrl-geocoder-${direction}`);

        const input = DOM.create("input", `nmapsgl-ctrl-geocoder-input nmapsgl-ctrl-geocoder-input-${direction}`);
        input.type = "text";
        input.placeholder = placeholder;
        input.addEventListener("keydown", debounce(this._keydown, 200));
        input.addEventListener("update", this._onChange);

        // Icon
        const iconWrapper = DOM.create("div", "nmapsgl-geocoder-icon-wrapper");
        const icon = DOM.create("div", `nmapsgl-geocoder-icon nmapsgl-icon-${direction !== "destination" ? "point" : "destination"}`);
        iconWrapper.appendChild(icon);
        if (direction !== "destination") {
            const dots = DOM.create("div", "nmapsgl-geocoder-icon nmapsgl-icon-dots");
            iconWrapper.appendChild(dots);
        }

        el.appendChild(iconWrapper);
        el.appendChild(input);
        const div = DOM.create("div", "nmapsgl-ctrl-geocoder-border");
        el.appendChild(div);

        // Icon to remove waypoint
        const removeWaypointBtn = DOM.create("div", "nmapsgl-routing-waypoint-remove");

        //Wrapper
        const waypointInputWrapper = DOM.create("div", "nmapsgl-routing-waypoint-input-wrapper");
        waypointInputWrapper.appendChild(el);
        waypointInputWrapper.appendChild(removeWaypointBtn);

        waypointInputWrapper.addEventListener("mouseenter", () => {
            if (Object.keys(this._routing).length > 2) removeWaypointBtn.style.visibility = "visible";
        });
        waypointInputWrapper.addEventListener("mouseleave", () => {
            if (Object.keys(this._routing).length > 2) removeWaypointBtn.style.visibility = "hidden";
        });

        removeWaypointBtn.addEventListener("click", () => {
            //Check if waypoint has value
            const query = this._routing[ direction ].input.value;

            // Remove input
            waypointInputWrapper.parentNode.removeChild(waypointInputWrapper);

            // Remove marker if exists
            if (this._routing[ direction ].marker) {
                this._routing[ direction ].marker.remove();
            }

            // Remove from variable
            delete this._routing[ direction ];

            // Check if is removing destination; Last waypoint should become the new destination
            if (direction === "destination") {
                // Input
                const waypointInput = this._createInputElement("destination");

                // Check last waypoint
                const elems = window.document.getElementsByClassName("nmapsgl-routing-waypoint-input-wrapper");
                if (elems.length > 0) {
                    const label = elems[ elems.length - 1 ].children[ 0 ].className.split("nmapsgl-ctrl-geocoder-")[ 1 ];

                    // Change waypoint to destination
                    this._routing[ "destination" ].selected = this._routing[ label ].selected;
                    this._routing[ "destination" ].input.value = this._routing[ label ].input.value;
                    if (this._routing[ label ].marker) {
                        this._handleMarker("destination", this._routing[ "destination" ].selected);
                    }

                    // Remove previous marker
                    if (this._routing[ label ].marker) this._routing[ label ].marker.remove();

                    // Remove waypoint
                    delete this._routing[ label ];

                    const inputWrapper = window.document.getElementsByClassName("nmapsgl-routing-input-wrapper");
                    if (inputWrapper.length !== 0) {
                        // Remove last waypoint
                        inputWrapper[ 0 ].removeChild(inputWrapper[ 0 ].lastChild);

                        // Add new child
                        inputWrapper[ 0 ].appendChild(waypointInput);
                    }
                }
            }

            // Recalculate Route
            if (query) {
                this._calculateRoute();
            } else {
                // Add waypoint
                if (Object.keys(this._routing).length < 10 && window.document.getElementsByClassName("nmapsgl-waypoint-btn-wrapper").length === 0) {
                    this._createAddWaypoint();
                }
            }

            if (Object.keys(this._routing).length === 2) this._reverseButton.style.display = "block";
        });

        // Store elements
        this._routing[ direction ] = {
            input,
            "selected": null
        };

        return waypointInputWrapper;
    }

    _createInstructions(route, id, profile) {
        const div = DOM.create("div", `nmapsgl-routing-instructions ${id === "result" ? "nmapsgl-routing-instructions-active" : ""}`);
        div.id = `instructions-${id}`;
        div.addEventListener("click", (e) => {
            const target = e.target as HTMLDivElement;
            if (target.id) {
                const id = target.id.split("-")[ 1 ];
                this._switchAlternative(id);
            }
        });

        const header = DOM.create("div", "nmapsgl-routing-instructions-header");
        header.id = `header-${id}`;

        const border = DOM.create("div", "nmapsgl-routing-instructions-header-active");

        const routeDesc = DOM.create("h4", "nmapsgl-routing-instructions-header-desc");
        routeDesc.innerHTML = `via ${route.name}`;
        routeDesc.id = `routeDesc-${id}`;

        const routeDistance = DOM.create("div", "nmapsgl-routing-instructions-distance");
        routeDistance.id = `routeDistance-${id}`;
        routeDistance.innerHTML = getDistance(route.total_distance, this.options.units);

        const moreDetails = DOM.create("div", "nmapsgl-routing-instructions-details");
        const moreDetailsLabel = DOM.create("span", "nmapsgl-routing-instructions-details-label");
        moreDetailsLabel.innerText = "More details";
        const moreDetailsIcon = DOM.create("div", "nmapsgl-routing-instructions-details-arrow down");
        moreDetails.appendChild(moreDetailsLabel);
        moreDetails.appendChild(moreDetailsIcon);
        moreDetails.addEventListener("click", () => {
            // Change arrow icon
            moreDetailsIcon.classList.toggle("up");

            // Toogle steps desc
            const elem = window.document.getElementById(`steps-${id}`);
            elem.classList.toggle("nmapsgl-routing-instructions-steps-hidden");
        });

        const routeDescWrapper = DOM.create("div", "nmapsgl-routing-instructions-desc-wrapper");
        routeDescWrapper.appendChild(routeDesc);
        routeDescWrapper.appendChild(routeDistance);
        // Add warning messages
        if (route[ "crosses" ]) {
            Object.keys(route.crosses).forEach((c) => {
                const msg = createWarnMsg(c);
                if (msg) {
                    const warnMsg = DOM.create("div", "nmapsgl-routing-instructions-warning");
                    warnMsg.innerText = msg;
                    routeDescWrapper.appendChild(warnMsg);
                }
            });
        }
        routeDescWrapper.appendChild(moreDetails);

        const icon = DOM.create("span", `nmapsgl-geocoder-icon nmapsgl-icon-instructions nmapsgl-icon-${profile.toLowerCase()}`);
        icon.id = `icon-${id}`;

        const descWrapper = DOM.create("div", "nmapsgl-routing-header-wrapper");
        descWrapper.id = `descWrapper-${id}`;
        descWrapper.appendChild(icon);
        descWrapper.appendChild(routeDescWrapper);

        const routeTime = DOM.create("div", "nmapsgl-routing-instructions-time");
        routeTime.id = `routeTime-${id}`;
        routeTime.innerHTML = getTime(route.total_time);

        const routeETA = DOM.create("div", "nmapsgl-routing-instructions-eta");
        routeETA.id = `routeETA-${id}`;
        routeETA.appendChild(routeTime);

        const headerWrap = DOM.create("div", "nmapsgl-routing-header-wrap");
        headerWrap.id = `headerWrap-${id}`;
        headerWrap.appendChild(descWrapper);
        headerWrap.appendChild(routeETA);

        header.appendChild(border);
        header.appendChild(headerWrap);
        div.appendChild(header);

        const steps = DOM.create("div", "nmapsgl-routing-instructions-steps nmapsgl-routing-instructions-steps-hidden");
        steps.id = `steps-${id}`;
        route.legs.forEach((l) => {
            if (l.steps) {
                l.steps.forEach((s, i) => {
                    const step = DOM.create("div", "nmapsgl-routing-instructions-step");
                    step.id = `step_${i}-${id}`;

                    const stepDescWrapper = DOM.create("div", "nmapsgl-routing-instructions-stepdescwrapper");
                    stepDescWrapper.id = `stepDescWrapper_${i}-${id}`;

                    const maneuver = DOM.create("div", "nmapsgl-routing-maneuver");
                    if (maneuvers[ s.maneuver ]) {
                        maneuver.classList.add(`nmapsgl-routing-maneuver-${s.maneuver}`);
                    }

                    step.addEventListener("click", () => {
                        this._map.flyTo({
                            center: [ s.location.lng, s.location.lat ],
                            speed: 1.3,
                            zoom: 15
                        });
                        if (!this._maneuverMarker) {
                            const el = DOM.create("div", "nmapsgl-maneuver-marker");
                            this._maneuverMarker = new Marker({ element: el })
                                .setLngLat([ s.location.lng, s.location.lat ])
                                .addTo(this._map);
                        } else {
                            this._maneuverMarker.setLngLat([ s.location.lng, s.location.lat ]);
                        }
                    });

                    const stepWrapper = DOM.create("div", "nmapsgl-routing-instructions-stepwrapper");
                    stepWrapper.id = `stepWrapper_${i}-${id}`;

                    const route = DOM.create("span", "nmapsgl-routing-instructions-route");
                    route.id = `route_${i}-${id}`;
                    route.innerHTML = s.instruction;

                    const distance = DOM.create("div", "nmapsgl-routing-instructions-distance");
                    distance.id = `distance_${i}-${id}`;
                    distance.innerHTML = getDistance(s.distance, this.options.units, true);

                    stepDescWrapper.appendChild(maneuver);
                    stepWrapper.appendChild(distance);
                    stepWrapper.appendChild(route);
                    step.appendChild(stepDescWrapper);
                    step.appendChild(stepWrapper);
                    steps.appendChild(step);
                });
            }
        });

        div.appendChild(steps);

        return div;
    }

    _createProfilesElement() {
        // Profiles
        const profileEL = DOM.create("div", "nmapsgl-routing-control-profiles");
        const profilesWrapper = DOM.create("div", "nmapsgl-routing-control-profiles-wrapper");
        // Check profile options
        let profilesKeys = Object.keys(this.options.profiles) as Profile[];
        const filteredProfiles = profilesKeys.filter(profile => this.options.profileIds.includes(profile));
        profilesKeys = filteredProfiles.length > 0 ? filteredProfiles : profilesKeys;
        this.activeProfile = profilesKeys.length > 0 ? profilesKeys[ 0 ] : undefined;
        profilesKeys.forEach((key) => {
            const profElem = DOM.create("div", "nmapsgl-routing-profile");
            const profElemIcon = DOM.create("div", `nmapsgl-routing-profile-icon nmapsgl-routing-profile-${key}`);
            profElemIcon.title = key;
            profElem.appendChild(profElemIcon);
            if (this.activeProfile === key) {
                profElem.classList.add("nmapsgl-routing-profile-active");
                profElemIcon.classList.add("nmapsgl-routing-profile-selected");
            }
            profElemIcon.addEventListener("click", this._switchProfile);
            profilesWrapper.appendChild(profElem);
        });

        profileEL.appendChild(profilesWrapper);
        return profileEL;
    }

    _createOptionsElement() {
        // Wrapper
        const optionsWrapper = DOM.create("div", "nmapsgl-routing-control-options-wrapper");

        // Options
        const optionsEL = DOM.create("div", "nmapsgl-routing-control-options");

        const optionsTextElem = DOM.create("a", "nmapsgl-routing-control-options-label");
        optionsTextElem.innerText = "Options";

        const optionsIcon = DOM.create("div", "nmapsgl-routing-control-options-arrow down");

        optionsEL.appendChild(optionsTextElem);
        optionsEL.appendChild(optionsIcon);
        optionsEL.addEventListener("click", this._toogleOptions);
        optionsWrapper.appendChild(optionsEL);

        // Settings
        const optionSettings = DOM.create("div", "nmapsgl-routing-control-options-settings hide");

        const optionsAvoids = DOM.create("div", "nmapsgl-routing-control-options-avoids");
        const optionsAvoidsLabel = DOM.create("p", "nmapsgl-routing-control-options-avoids-label");
        optionsAvoidsLabel.textContent = "Avoid";
        optionsAvoids.appendChild(optionsAvoidsLabel);

        let optionsKeys = options.map(opt => opt.name) as RoutingOptions[];
        const filteredOptions = optionsKeys.filter(option => this.options.routingOptions.includes(option));
        optionsKeys = filteredOptions.length > 0 ? filteredOptions : optionsKeys;
        optionsKeys.forEach((option) => {
            const obj = options.find(opt => opt.name === option);
            if (obj) {
                const checkbox = DOM.create("div", "nmapsgl-routing-control-options-avoid");
                const input = DOM.create("input", "nmapsgl-routing-control-options-input");
                input.type = "checkbox";
                input.id = obj.value;
                input.addEventListener("change", () => {
                    const selected = Object.values(this._routing).filter(d => d[ "selected" ]);
                    if (selected.length >= 2) this._calculateRoute();

                    // Calculate number of selected avoids
                    const optionsElems = window.document.getElementsByClassName("nmapsgl-routing-control-options-input");
                    const optionsSelected = Array.from(optionsElems).filter((e: HTMLInputElement) => e.checked).length;
                    if (optionsSelected > 0) {
                        optionsTextElem.innerText = `Options (${optionsSelected})`;
                    } else {
                        optionsTextElem.innerText = "Options";
                    }
                });
                const label = DOM.create("label", "nmapsgl-routing-control-options-input-label");
                label.setAttribute("for", obj.value);
                label.innerText = obj.name;
                checkbox.appendChild(input);
                checkbox.appendChild(label);
                optionsAvoids.appendChild(checkbox);
            }
        });

        optionSettings.appendChild(optionsAvoids);
        const subOptionsWrapper = DOM.create("div", "nmapsgl-routing-control-suboptions-wrapper");

        const optionsDistance = DOM.create("form", "nmapsgl-routing-control-options-distances");
        const optionsDistanceLabel = DOM.create("p", "nmapsgl-routing-control-options-distances-label");
        optionsDistanceLabel.textContent = "Distance units";
        optionsDistance.appendChild(optionsDistanceLabel);

        [ "Metric", "Imperial" ].forEach((distance) => {
            const checkbox = DOM.create("div", "nmapsgl-routing-control-options-distance");
            const input = DOM.create("input", "nmapsgl-routing-control-options-distance-input");
            input.type = "radio";
            input.id = distance;
            input.value = distance;
            input.name = "distance";

            input.addEventListener("change", this._changeUnits);

            const label = DOM.create("label", "nmapsgl-routing-control-options-distance-input-label");
            label.setAttribute("for", distance);
            label.innerText = distance;
            checkbox.appendChild(input);
            checkbox.appendChild(label);
            // Set distance unit option
            if (distance === this.options.units) {
                input.checked = true;
            }
            optionsDistance.appendChild(checkbox);
        });

        const optionsAllow = DOM.create("div", "nmapsgl-routing-control-options-allow");
        const optionsAllowLabel = DOM.create("p", "nmapsgl-routing-control-options-allow-label");
        optionsAllowLabel.textContent = "Enable";
        optionsAllow.appendChild(optionsAllowLabel);

        const includeOptions = DOM.create("div", "nmapsgl-routing-control-options-include");
        const checkbox = DOM.create("div", "nmapsgl-routing-control-options-traffic");
        const input = DOM.create("input", "nmapsgl-routing-control-options-traffic-input");
        input.type = "checkbox";
        input.id = "traffic";
        input.addEventListener("change", this._calculateRoute);
        // Set distance unit option
        if (this.options.traffic) {
            input.checked = true;
        }
        const label = DOM.create("label", "nmapsgl-routing-control-options-traffic-label");
        label.innerText = "Traffic";
        label.setAttribute("for", "traffic");
        checkbox.appendChild(input);
        checkbox.appendChild(label);
        includeOptions.appendChild(checkbox);
        optionsAllow.appendChild(includeOptions);

        subOptionsWrapper.appendChild(optionsAllow);
        subOptionsWrapper.appendChild(optionsDistance);
        optionSettings.appendChild(subOptionsWrapper);

        // Settings Wrapper
        const settingsWrapper = DOM.create("div", "nmapsgl-routing-control-settings-wrapper");
        settingsWrapper.appendChild(optionsWrapper);
        settingsWrapper.appendChild(optionSettings);

        return settingsWrapper;
    }

    _createAddWaypoint() {
        // Add waypoint wrapper
        const waypointButtonWrapper = DOM.create("div", "nmapsgl-waypoint-btn-wrapper");

        // Waypoint icons
        const waypointButtonIcons = DOM.create("div", "nmapsgl-waypoint-btn-icons");
        const waypointButtonPlus = DOM.create("div", "nmapsgl-geocoder-icon nmapsgl-waypoint-btn");
        const waypointButtonDots = DOM.create("div", "nmapsgl-geocoder-icon nmapsgl-icon-dots");
        waypointButtonIcons.appendChild(waypointButtonPlus);
        waypointButtonIcons.appendChild(waypointButtonDots);
        waypointButtonWrapper.appendChild(waypointButtonIcons);

        // Text
        const waypointButtonSpan = DOM.create("span", "nmapsgl-waypoint-btn-span");
        waypointButtonSpan.innerText = "Add destination";
        waypointButtonWrapper.appendChild(waypointButtonSpan);

        // Border
        const div = DOM.create("div", "nmapsgl-ctrl-geocoder-border");
        waypointButtonWrapper.appendChild(div);

        const waypointWrapper = DOM.create("div", "nmapsgl-waypoint-wrapper");
        waypointWrapper.appendChild(waypointButtonWrapper);
        this._container.insertBefore(waypointButtonWrapper, this._container.children[ 2 ]);

        waypointButtonWrapper.addEventListener("click", () => {
            const currentWaypointNumber = this._waypointNumber;
            const label = `waypoint-${currentWaypointNumber}`;

            // Input
            const waypointInput = this._createInputElement(label);

            const inputWrapper = window.document.getElementsByClassName("nmapsgl-routing-input-wrapper");
            if (inputWrapper.length !== 0) {
                // Chande destination to waypoint;
                this._routing[ label ].selected = this._routing[ "destination" ].selected;
                this._routing[ label ].input.value = this._routing[ "destination" ].input.value;
                if (this._routing[ "destination" ].selected) {
                    this._handleMarker(label, this._routing[ "destination" ].selected);
                }

                // Clear destintion values
                this._routing[ "destination" ].selected = null;
                this._routing[ "destination" ].input.value = null;
                this._routing[ "destination" ].marker.remove();
                this._routing[ "destination" ].marker = null;

                inputWrapper[ 0 ].insertBefore(waypointInput, inputWrapper[ 0 ].lastChild);

                // Remove Add waypoint
                waypointButtonWrapper.parentNode.removeChild(waypointButtonWrapper);
                // Remove reverse if there's more than 2 waypoints
                if (Object.keys(this._routing).length > 2) {
                    this._reverseButton.style.display = "none";
                }
            }

            this._waypointNumber++;
        });

    }

    _onChange = (e) => {
        const direction = e.target.className.split("nmapsgl-ctrl-geocoder-input-")[ 1 ];
        const pointElems = this._routing[ direction ];

        const marker = direction;
        const inputEl = pointElems.input;
        const selectedEl = pointElems.selected;

        if (selectedEl) {
            if (this.options.flyTo) {
                this._map.flyTo({
                    center: selectedEl.location,
                    speed: 1.3,
                    zoom: this.options.zoom
                });
            } else {
                this._map.jumpTo({
                    center: selectedEl.location,
                    zoom: this.options.zoom
                });
            }
            this._handleMarker(marker, selectedEl);

            // After selecting a feature, re-focus the textarea and set
            // cursor at start.
            inputEl.focus();
            inputEl.scrollLeft = 0;
            inputEl.setSelectionRange(0, 0);

            // Check if both elems are sellected
            const selected = Object.values(this._routing).filter(d => d[ "selected" ]);
            if (selected.length >= 2) {
                this._calculateRoute();
            }
        }
    };

    _clear(direction) {
        // Initialize variables
        const pointElems = this._routing[ direction ];
        pointElems.selected = null;

        // Remove marker and maneuverMarker
        this._removeMarker(direction);
        if (this._maneuverMarker) this._maneuverMarker.remove();

        // Remove Instructions
        const nodes = window.document.getElementsByClassName("nmapsgl-routing-instructions-wrapper");
        Array.from(nodes).forEach(node => node.parentNode.removeChild(node));

        // Remove Geometry
        this._removeLayers();
        this._geojson = null;

        // Hide settings
        const optionsEL = window.document.getElementsByClassName("nmapsgl-routing-control-settings-wrapper");
        if (optionsEL.length > 0) {
            (optionsEL[ 0 ] as HTMLElement).style.display = "none";
        }

        // Remove Add waypoint option
        const addWaypoint = window.document.getElementsByClassName("nmapsgl-waypoint-btn-wrapper");
        if (addWaypoint.length > 0) {
            addWaypoint[ 0 ].remove();
        }
    }

    _reverse = () => {
        const pointElems = Object.values(this._routing);
        if (pointElems.length === 2 && pointElems.some(e => e[ "selected" ] !== null)) {
            // Get waypoints order
            const elems = window.document.getElementsByClassName("nmapsgl-routing-waypoint-input-wrapper");
            const order = Array.from(elems).map(e => (e.firstChild as HTMLDivElement).className.split("nmapsgl-ctrl-geocoder-")[ 1 ]);

            const firstPoint = this._routing[ order[ 0 ] ];
            const secPoint = this._routing[ order[ 1 ] ];

            // Revert inputs
            const firstInput = firstPoint.input.value;
            firstPoint.input.value = secPoint.input.value;
            secPoint.input.value = firstInput;

            //Update selected
            const firstSelected = firstPoint.selected;
            firstPoint.selected = secPoint.selected;
            secPoint.selected = firstSelected;

            // Revert markers
            if (firstPoint.marker || secPoint.marker) {
                let firstMarkerCoord, secMarkerCoord;
                if (firstPoint.marker) {
                    firstMarkerCoord = firstPoint.marker.getLngLat();
                    firstPoint.marker.remove();
                    firstPoint.marker = null;
                }

                if (secPoint.marker) {
                    secMarkerCoord = secPoint.marker.getLngLat();
                    secPoint.marker.remove();
                    secPoint.marker = null;
                }

                // Add new ones
                const el = DOM.create("div", "nmapsgl-point-marker");

                if (secMarkerCoord) {
                    firstPoint.marker = new Marker({ element: el });
                    firstPoint.marker
                        .setLngLat(secMarkerCoord)
                        .addTo(this._map);
                }

                if (firstMarkerCoord) {
                    secPoint.marker = new Marker()
                        .setLngLat(firstMarkerCoord)
                        .addTo(this._map);
                }

                if (firstMarkerCoord && secMarkerCoord) {
                    // Calculate new route
                    this._calculateRoute();
                }
            }
        }
    };

    _keydown = (e) => {
        const direction = e.target.className.split("nmapsgl-ctrl-geocoder-input-")[ 1 ];
        const pointElems = this._routing[ direction ];

        if (e.key === "Enter") {
            // Clear suggestions
            this._suggestions.innerHTML = "";

            // Remove Instructions
            const nodes = window.document.getElementsByClassName("nmapsgl-routing-instructions-wrapper");
            Array.from(nodes).forEach(node => node.parentNode.removeChild(node));

            const searchInput = e.target.value;
            if (searchInput) {
                // Hide settings
                const optionsEL = window.document.getElementsByClassName("nmapsgl-routing-control-settings-wrapper");
                if (optionsEL.length > 0) {
                    (optionsEL[ 0 ] as HTMLDivElement).style.display = "none";
                }

                // Create loading
                this._suggestions.classList.add("nmapsgl-routing-inputs-suggestions-show");
                this._suggestions.classList.add("nmapsgl-routing-inputs-suggestions-loading");
                const loadingEL = DOM.create("span", "nmapsgl-icon-loading");
                this._suggestions.appendChild(loadingEL);

                if (this.controller) {
                    this.controller.abort();
                }

                this.controller = new AbortController();

                // Initialize request
                let request;

                if (/(-?\d+\.?\d*)[, ]+(-?\d+\.?\d*)[ ]*$/.test(searchInput)) {
                    // parse coordinates
                    const coords = searchInput.split(/[\s(,)?]+/).map((c) => {
                        return parseFloat(c);
                    });
                    request = reverseGeocode(coords, this.controller, this.options.limit);
                } else {
                    const center = this._map.getCenter();
                    request = genericSearch(searchInput, center, this.controller, this.options.limit);
                }

                request
                    .then(({ data }) => {
                        this.controller = null;

                        // Remove loading
                        this._suggestions.classList.remove("nmapsgl-routing-inputs-suggestions-loading");
                        if (this._suggestions.contains(loadingEL)) this._suggestions.removeChild(loadingEL);

                        if (data.results.length) {
                            const resultsEl = DOM.create("div", "nmapsgl-routing-inputs-suggestions-results");
                            data.results.forEach((r) => {
                                const item = this.render(r);
                                item.addEventListener("click", () => {
                                    const inputLabel = this.getItemValue(r);
                                    // Define selected
                                    pointElems.selected = { "address": inputLabel, "location": r.location };

                                    // Set input and fire change event
                                    e.target.value = inputLabel;
                                    /* eslint-disable */
                                    const event = new Event('update');
                                    /* eslint-enable */
                                    e.target.dispatchEvent(event);

                                    // Remove suggestions
                                    this._suggestions.innerHTML = "";
                                    this._suggestions.classList.remove("nmapsgl-routing-inputs-suggestions-show");
                                });
                                resultsEl.appendChild(item);
                            });
                            this._suggestions.appendChild(resultsEl);
                        } else {
                            pointElems.selected = null;
                            const errorMessage = DOM.create("div", "nmapsgl-geocoder-error nmaps-gl-geocoder--no-results", this._suggestions);
                            errorMessage.innerText = "No results found";
                        }
                    })
                    .catch((e) => {
                        if (e.name !== "AbortError") {
                            this.controller = null;

                            // Remove loading
                            this._suggestions.classList.remove("nmapsgl-routing-inputs-suggestions-loading");
                            if (this._suggestions.contains(loadingEL)) this._suggestions.removeChild(loadingEL);

                            pointElems.selected = null;
                            const errorMessage = DOM.create("div", "nmapsgl-geocoder-error", this._suggestions);
                            errorMessage.innerText = "There was an error reaching the server";
                        }
                    });
            }
        } else if (!geocoderExcludedkeys.includes(e.key)) {
            pointElems.selected = null;
            if (e.target.value === "") {
                this._clear(direction);
            }
            // Remove suggestions
            this._suggestions.innerHTML = "";
        }
    };

    _switchProfile = (e) => {
        this.activeProfile = e.target.title;
        const profiles = window.document.getElementsByClassName("nmapsgl-routing-profile");
        Array.from(profiles).forEach((p) => {
            p.classList.remove("nmapsgl-routing-profile-active");
            (p.firstChild as HTMLDivElement).classList.remove("nmapsgl-routing-profile-selected");
        });
        e.target.parentNode.classList.add("nmapsgl-routing-profile-active");
        e.target.classList.add("nmapsgl-routing-profile-selected");

        // Check if route is already inserted
        const selected = Object.values(this._routing).filter(d => d[ "selected" ]);
        if (selected.length >= 2) {
            // Remove Geometry
            this._removeLayers();
            this._geojson = null;
            this._calculateRoute();
        }
    };

    _toogleOptions() {
        // Change arrow icon
        const elem = window.document.getElementsByClassName("nmapsgl-routing-control-options");
        const arrow = Array.from(elem)[ 0 ].lastChild as HTMLElement;
        arrow.classList.toggle("up");

        const settingsElem = window.document.getElementsByClassName("nmapsgl-routing-control-options-settings");
        const settings = Array.from(settingsElem)[ 0 ];
        settings.classList.toggle("hide");
    }

    _changeUnits = (e) => {
        this.options.units = e.target.value;
        // Check if route is already inserted
        const selected = Object.values(this._routing).filter(d => d[ "selected" ]);
        if (selected.length >= 2) {
            const distances = Array.from(window.document.getElementsByClassName("nmapsgl-routing-instructions-distance"));
            distances.forEach((distance) => {
                const header = (distance.parentNode as HTMLElement).classList.contains("nmapsgl-routing-instructions-desc-wrapper");
                const newDistance = convertDistance(distance.innerHTML, header);
                distance.innerHTML = newDistance;
            });
        }
    };

    _calculateRoute = () => {
        // Get waypoints order
        const elems = window.document.getElementsByClassName("nmapsgl-routing-waypoint-input-wrapper");
        const order = Array.from(elems).map(e => (e.firstChild as HTMLElement).className.split("nmapsgl-ctrl-geocoder-")[ 1 ]);
        const coordinates = order.map((o) => {
            const dir = this._routing[ o ];
            if (dir.marker) {
                return dir.marker.getLngLat();
            }
            return undefined;
        }).filter(m => m !== undefined);

        //Fit bounds
        const bounds = coordinates.reduce((bounds, coord) => {
            return bounds.extend(coord);
        }, new LngLatBounds(coordinates[ 0 ], coordinates[ 0 ]));

        this._map.fitBounds(bounds, {
            padding: 100
        });

        // Remove Instructions
        const nodes = window.document.getElementsByClassName("nmapsgl-routing-instructions-wrapper");
        Array.from(nodes).forEach(node => node.parentNode.removeChild(node));

        // Display options
        const optionsEL = window.document.getElementsByClassName("nmapsgl-routing-control-settings-wrapper");
        if (optionsEL.length > 0) {
            (optionsEL[ 0 ] as HTMLElement).style.display = "block";
        }

        // Clear previous geometry
        this._geojson = {
            type: "FeatureCollection",
            features: []
        };

        const source = this._map.getSource(directionsSourceName) as GeoJSONSource;
        if (source) source.setData(this._geojson);

        // Check if contains more than 2 waypoints
        if (coordinates.length >= 2) {
            // Create loading
            this._instructions = DOM.create("div", "nmapsgl-routing-instructions-wrapper nmapsgl-routing-instructions-loading");
            const loadingEL = DOM.create("span", "nmapsgl-icon-loading");
            this._instructions.appendChild(loadingEL);
            this._container.appendChild(this._instructions);

            // Get waypoints and normalize coords
            const waypoints = coordinates.map((c) => {
                const lng = Number(c.lng);
                if (lng < -180) {
                    c.lng = lng + Number(180);
                }
                if (lng > 180) {
                    c.lng = lng - Number(180);
                }
                return c;
            });

            // Get settings
            const settings = {};
            const optionsElem = window.document.getElementsByClassName("nmapsgl-routing-control-options-input");
            Array.from(optionsElem).forEach((option: HTMLInputElement) => {
                settings[ option.id ] = !option.checked;
            });

            // Get include options
            const traffic = (window.document.getElementsByClassName("nmapsgl-routing-control-options-traffic-input")[ 0 ] as HTMLInputElement).checked;

            const profile = Object.assign({}, this.options.profiles[ this.activeProfile ], settings);

            if (this.controller) {
                this.controller.abort();
            }
            this.controller = new AbortController();
            getDirections(waypoints, profile, this.controller, 0, true, 2, true, traffic)
                .then(({ data }) => {
                    this.controller = null;
                    // Check if inputs are still selected
                    const selected = Object.values(this._routing).filter(d => d[ "selected" ]);
                    if (selected.length >= 2) {
                        // remove loading
                        if (this._instructions.contains(loadingEL)) this._instructions.removeChild(loadingEL);

                        if (data.status !== "OK") {
                            const errorWrapper = DOM.create("div", "nmapsgl-routing-error-wrapper");
                            const error = DOM.create("span");
                            error.innerText = "Unfortunately we couldn't calculate routing for this type of transportation.";
                            const icon = DOM.create("span", "nmapsgl-geocoder-icon nmapsgl-icon-alert");
                            errorWrapper.appendChild(icon);
                            errorWrapper.appendChild(error);
                            this._instructions.appendChild(errorWrapper);
                        } else {
                            // Add waypoint
                            if (Object.keys(this._routing).length < 10 && window.document.getElementsByClassName("nmapsgl-waypoint-btn-wrapper").length === 0) {
                                this._createAddWaypoint();
                            }

                            // Verify if source exists
                            this.loadSource();

                            // Reset variables to populate with new routing
                            this._layers = [];
                            this._selectedAlternative = "result";

                            const geometries = flatten([
                                flatten(data.result.legs.map((leg) => {
                                    return { "name": "result", "geometry": leg.geometry, "eta": data.result.total_time, "distance": data.result.total_distance };
                                })),
                                flatten((data.alternatives || []).map((c, i) => {
                                    return c.legs.map((legAlt) => {
                                        return { "name": `alternative_${i}`, "geometry": legAlt.geometry, "eta": data.alternatives[ i ].total_time, "distance": data.alternatives[ i ].total_distance };
                                    });
                                }))
                            ]);
                            geometries.forEach((g) => {
                                const features = [];
                                const decoded = polyline.decode(g[ "geometry" ], 6).map((c) => {
                                    return c.reverse();
                                });
                                decoded.forEach((c) => {
                                    const previous = features[ features.length - 1 ];

                                    if (previous) {
                                        previous.geometry.coordinates.push(c);
                                    } else {
                                        const segment = {
                                            "type": "Feature",
                                            "properties": {
                                                "name": g[ "name" ]
                                            },
                                            "geometry": {
                                                "type": "LineString",
                                                "coordinates": []
                                            }
                                        };
                                        // New segment starts with previous segment's last coordinate.
                                        if (previous) segment.geometry.coordinates.push(previous.geometry.coordinates[ previous.geometry.coordinates.length - 1 ]);
                                        segment.geometry.coordinates.push(c);
                                        features.push(segment);
                                    }
                                });
                                this._geojson.features = this._geojson.features.concat(features);

                                // Define new layer
                                const style = this._map.getStyle();
                                const lineColor = getCustomColor(style.name);
                                const layer: LayerSpecification = {
                                    "id": `routing-${g[ "name" ]}`,
                                    "type": "line",
                                    "source": directionsSourceName,
                                    "paint": {
                                        "line-color": g[ "name" ] === "result" ? "#4ca0fe" : lineColor,
                                        "line-width": 4
                                    },
                                    "layout": {
                                        "visibility": "visible"
                                    },
                                    "filter": [ "==", "name", g[ "name" ] ]
                                };
                                this._layers.push(layer);

                                // Check if map has this layer
                                if (!this._map.getLayer(`routing-${g[ "name" ]}`)) {
                                    this._map.addLayer(layer);
                                    if (g[ "name" ] !== "result" && this._map.getLayer("routing-result")) {
                                        this._map.moveLayer(`routing-${g[ "name" ]}`, "routing-result");
                                    }
                                    this._map.on("click", `routing-${g[ "name" ]}`, () => {
                                        this._switchAlternative(g[ "name" ]);
                                    });
                                } else {
                                    style.layers.forEach((layer) => {
                                        if (directionsSourceName === layer[ "source" ]) {
                                            if (layer.id !== "routing-result") {
                                                this._map.setPaintProperty(layer.id, "line-color", lineColor);
                                                this._map.moveLayer(layer.id, "routing-result");
                                            }
                                        }
                                    });
                                    this._map.setPaintProperty("routing-result", "line-color", "#4ca0fe");
                                }

                                // Change the cursor to a pointer when the mouse is over.
                                this._map.on("mouseenter", `routing-${g[ "name" ]}`, (e) => {
                                    if (this._popup) {
                                        this._popup.remove();
                                    }
                                    this._popup = new Popup({ closeButton: false, className: "nmapsgl-routing-popup" })
                                        .setLngLat(e.lngLat)
                                        .setHTML(`<div style="display: flex; justify-content: center;align-items: center"><div class="nmapsgl-icon-instructions-popup nmapsgl-icon-${this.activeProfile.toLowerCase()}"></div><div style="margin-left: 8px"><p style="font-weight:bold">${getTime(g[ "eta" ])}</p><p>${getDistance(g[ "distance" ], this.options.units)}</p></div></div>`)
                                        .addTo(this._map);
                                    this._map.getCanvas().style.cursor = "pointer";
                                });
                                // Change it back to a pointer when it leaves.
                                this._map.on("mouseleave", `routing-${g[ "name" ]}`, () => {
                                    this._map.getCanvas().style.cursor = "";
                                    if (this._popup) {
                                        this._popup.remove();
                                    }
                                });
                            });
                            (this._map.getSource(directionsSourceName) as GeoJSONSource).setData(this._geojson);

                            // Remove Instructions
                            const nodes = window.document.getElementsByClassName("nmapsgl-routing-instructions-wrapper");
                            Array.from(nodes).forEach(node => node.parentNode.removeChild(node));

                            // Add instructions
                            this._instructions = DOM.create("div", "nmapsgl-routing-instructions-wrapper");
                            this._instructions.appendChild(this._createInstructions(data.result, "result", this.activeProfile));
                            if (data.alternatives) {
                                data.alternatives.forEach((alternative, i) => {
                                    this._instructions.appendChild(this._createInstructions(alternative, `alternative_${i}`, this.activeProfile));
                                });
                            }
                            this._container.appendChild(this._instructions);
                        }
                    }
                })
                .catch((e) => {
                    if (e.name !== "AbortError") {
                        this.controller = null;
                        // remove loading
                        if (this._instructions.hasChildNodes()) this._instructions.removeChild(loadingEL);
                        const error = DOM.create("span");
                        error.innerText = "There was an error reaching the server";
                        const icon = DOM.create("span", "nmapsgl-geocoder-icon nmapsgl-icon-alert");
                        this._instructions.appendChild(icon);
                        this._instructions.appendChild(error);
                    }
                });
        }
    };

    _switchAlternative(id) {
        // Switch alternative on elems
        const elems = window.document.getElementsByClassName("nmapsgl-routing-instructions-active");
        Array.from(elems).forEach((el) => {
            el.classList.remove("nmapsgl-routing-instructions-active");
        });

        const elemId = window.document.getElementById(`instructions-${id}`);
        elemId.classList.add("nmapsgl-routing-instructions-active");

        // Update Layers
        const style = this._map.getStyle();
        const lineColor = getCustomColor(style.name);
        style.layers.forEach((layer) => {
            if (directionsSourceName === layer[ "source" ]) {
                this._map.setPaintProperty(layer.id, "line-color", lineColor);
                if (layer.id !== `routing-${id}`) {
                    this._map.moveLayer(layer.id, `routing-${id}`);
                }
            }
        });
        this._map.setPaintProperty(`routing-${id}`, "line-color", "#4ca0fe");
        this._selectedAlternative = id;
    }

    _handleMarker(marker, selected) {
        // clean up any old marker that might be present
        if (!this._map) {
            return;
        }
        this._removeMarker(marker);
        if (selected.location) {
            let mapMarker = new Marker();
            if (marker !== "destination") {
                const el = DOM.create("div", "nmapsgl-point-marker");
                mapMarker = new Marker({ element: el });
            }
            mapMarker
                .setLngLat(selected.location)
                .addTo(this._map);
            this._routing[ marker ][ "marker" ] = mapMarker;
        }
        return this;
    }

    _addPoint(point: LngLat, direction) {
        // Normalize coordinates
        // eslint-disable-next-line prefer-const
        let { lat, lng } = point;
        if (lng < -180) lng = lng + 180;
        else if (lng > 180) lng = lng - 180;
        const coordinates = { lng: +lng.toFixed(6), lat: +lat.toFixed(6) };

        roadsInfo(coordinates)
            .then(({ data }) => {
                // Get starting point
                const elems = window.document.getElementsByClassName("nmapsgl-routing-waypoint-input-wrapper");
                const startingPoint = (elems[ 0 ].firstChild as HTMLElement).className.split("nmapsgl-ctrl-geocoder-")[ 1 ];
                let address = `${coordinates.lat}, ${coordinates.lng}`;
                let snapPoint = point;

                if (data.snapped_roads.length > 0) {
                    address = data.snapped_roads[ 0 ].address.replace("\n", ", ");
                    snapPoint = data.snapped_roads[ 0 ].snap_point;
                }

                if (direction === "waypoint") {
                    const currentWaypointNumber = this._waypointNumber;
                    const label = `waypoint-${currentWaypointNumber}`;

                    // Input
                    const waypointInput = this._createInputElement(label);

                    const inputWrapper = window.document.getElementsByClassName("nmapsgl-routing-input-wrapper");
                    if (inputWrapper.length !== 0) {
                        // Chande destination to waypoint;
                        this._routing[ label ].selected = this._routing[ "destination" ].selected;
                        this._routing[ label ].input.value = this._routing[ "destination" ].input.value;
                        if (this._routing[ "destination" ].selected) {
                            this._handleMarker(label, this._routing[ "destination" ].selected);
                        }

                        // Define destintion values
                        this._routing[ "destination" ].selected = { "name": address, "location": snapPoint };
                        this._routing[ "destination" ].input.value = address;
                        if (this._routing[ "destination" ].marker) this._routing[ "destination" ].marker.remove();
                        this._routing[ "destination" ].marker = new Marker().setLngLat(snapPoint).addTo(this._map);

                        inputWrapper[ 0 ].insertBefore(waypointInput, inputWrapper[ 0 ].lastChild);

                        // Remove Add waypoint
                        const waypointButtonWrapper = window.document.getElementsByClassName("nmapsgl-waypoint-btn-wrapper");
                        if (waypointButtonWrapper.length > 0) {
                            waypointButtonWrapper[ 0 ].parentNode.removeChild(waypointButtonWrapper[ 0 ]);
                        }
                        // Remove reverse if there's more than 2 waypoints
                        if (Object.keys(this._routing).length > 2) {
                            this._reverseButton.style.display = "none";
                        }
                    }
                    this._waypointNumber++;
                } else if (direction === "destination") {
                    const destination = this._routing[ "destination" ];
                    // Add Marker
                    destination.marker = new Marker().setLngLat(snapPoint).addTo(this._map);
                    // Set selected
                    destination.input.value = address;
                    destination.selected = { "name": address, "location": snapPoint };
                } else {
                    const origin = this._routing[ startingPoint ];
                    // Add Marker
                    const el = DOM.create("div", "nmapsgl-point-marker");
                    origin.marker = new Marker({ element: el }).setLngLat(snapPoint).addTo(this._map);
                    // Set selected
                    origin.input.value = address;
                    origin.selected = { "name": address, "location": snapPoint };
                }

                // Check if route has to be calculated
                const selected = Object.values(this._routing).filter(d => d[ "selected" ]);
                if (selected.length >= 2) {
                    this._calculateRoute();
                }
            }).catch((e) => {
                console.error(e);
            });
    }

    render(item) {
        const distance = item.distance > 100 ? `${(item.distance / 1000).toFixed(2)} km` : `${item.distance} m`;
        const regex = new RegExp(`${item.name},?`);
        const filterAddress = item.formatted_address.replace(regex, "");
        const elem = DOM.create("div", "nmapsgl-ctrl-geocoder-wrapper");
        switch (item.type) {
            case "country":
                elem.innerHTML += `<div class="nmapsgl-ctrl-geocoder-icon nmapsgl-ctrl-geocoder-${item.type}"></div><div class="nmapsgl-ctrl-geocoder--suggestion"><div><div class="nmapsgl-ctrl-geocoder--suggestion-title">${item.name}</div><div class="nmapsgl-ctrl-geocoder--suggestion-address"><span style="font-weight: 600">${distance}</span></div></div>`;
                return elem;
            default:
                elem.innerHTML += `<div class="nmapsgl-ctrl-geocoder-icon nmapsgl-ctrl-geocoder-${item.type}"></div><div class="nmapsgl-ctrl-geocoder--suggestion"><div class="nmapsgl-ctrl-geocoder--suggestion-title">${item.name}</div><div class="nmapsgl-ctrl-geocoder--suggestion-address"><span style="font-weight: 600">${distance}</span><span class="nmapsgl-dot"></span><span class="nmapsgl-format-address">${filterAddress}</span></div></div>`;
                return elem;
        }
    }

    getItemValue(item) {
        /*eslint indent: [2, 4, {"SwitchCase": 1}]*/
        switch (item.type) {
            case "country":
                return item.name;
            case "poi":
                return `${item.name}, ${item.formatted_address.replace("\n", ", ")}`;
            default:
                return item.formatted_address.replace("\n", ", ");
        }
    }

    _hasOrigin() {
        let hasOrigin = null;
        Object.entries(this._routing).forEach(([ k, v ]) => {
            if (k !== "destination" && v[ "selected" ] === null) {
                hasOrigin = false;
            }
        });
        return hasOrigin;
    }

    _hasDestination() {
        if (this._routing[ "destination" ]) {
            return this._routing[ "destination" ].selected !== null;
        } else {
            return null;
        }
    }

    _removeLayers() {
        this._layers = [];
        this._selectedAlternative = null;
        const style = this._map.getStyle();
        style.layers.forEach((layer) => {
            if (directionsSourceName === layer[ "source" ]) {
                this._map.removeLayer(layer.id);
            }
        });
    }

    _removeMarker(point) {
        const pointElems = this._routing[ point ];
        if (pointElems[ "marker" ] && pointElems.marker) {
            pointElems.marker.remove();
            pointElems.marker = null;
        }
    }
}

function getCustomColor(styleName) {
    let color;
    switch (true) {
        case /dark/i.test(styleName):
            color = "#4b6394";
            break;
        case /light/i.test(styleName):
        case /satellite/i.test(styleName):
            color = "#d6e5f5";
            break;
        default:
            color = "#aed1f8";
            break;
    }
    return color;
}

function getTime(time) {
    const treatedTime = Math.round(time / 60);
    if (time < 60) {
        return `${Math.round(time)} sec`;
    } else {
        return treatedTime > 60 ? `${Math.trunc(treatedTime / 60)} h ${treatedTime % 60} min` : `${treatedTime} min`;
    }
}

function getDistance(distance, units, full = false) {
    if (units === "Imperial") {
        const miles = distance * 0.000621371192;
        return miles < 1 ? `${miles.toFixed(2)} mile` : `${miles.toFixed(1)} miles`;
    } else {
        return distance > 1000 ? `${Math.round(distance / 1000)} ${full ? "kilometers" : "km"}` : `${Math.round(distance)} ${full ? "meters" : "m"}`;
    }
}

function convertDistance(distance, short = false) {
    const distanceElem = distance.split(" ");
    const distanceValue = distanceElem[ 0 ];
    const distanceMeasure = distanceElem[ 1 ];
    if (distanceMeasure === "kilometers" || distanceMeasure === "km") {
        return `${(distanceValue * 0.621371).toFixed(1)} miles`;
    } else if (distanceMeasure === "meters" || distanceMeasure === "m") {
        return `${(distanceValue * 0.0006213712).toFixed(2)} mile`;
    } else if (distanceMeasure === "miles") {
        return `${(distanceValue * 1.60934).toFixed(0)} ${short ? "km" : "kilometers"}`;
    } else if (distanceMeasure === "mile") {
        return `${(distanceValue * 1609.34).toFixed(0)} ${short ? "m" : "meters"}`;
    } else {
        return distance;
    }
}

function createWarnMsg(cross) {
    let msg = "";
    switch (true) {
        case cross === "tolls":
            msg = "This route has tolls.";
            break;
        case cross === "ferry":
            msg = "This route includes a ferry.";
            break;
        case cross === "motorways":
            msg = "This route has motorways.";
            break;
        case cross === "unpaved":
            msg = "This route may have unpaved roads.";
            break;
        case cross === "countrycrossing":
            msg = "This route may cross country borders.";
            break;
        default:
            break;
    }
    return msg;
}
